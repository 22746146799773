import { AgGridAngular } from '@ag-grid-community/angular';
import {
  AllModules,
  ColDef,
  ColumnState,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  MenuItemDef,
  Module,
  RowSelectedEvent,
  SideBarDef,
  ValueFormatterParams,
} from '@ag-grid-enterprise/all-modules';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridGroupModel,
  CompiereDataGridRequestJSON,
  CompiereDataGridResponseJSON,
  CompiereDataGridSortModel,
  CompiereDataGridType,
  DataStore,
  DataStoreRequest,
  DataStoreStatus,
} from '@compiere-ws/models/compiere-data-json';
import { CompiereExportDataService } from '@compiere-ws/services/compiere-export-data/compiere-export-data.service';
import { PrintReportService } from '@compiere-ws/services/compiere-print-report/print-report.service';
import {
  GridPreference,
  GridPreferenceType,
  GridPreferencesService,
} from '@compiere-ws/services/grid-preferences/grid-preferences.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { SpecificGridQuery } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import {
  GridViewUiComponent,
  RowSelectionType,
} from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTableDataHeader } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { GridWidgetComponent } from '@iupics/modules/iupics-widgets/components/grid-widget/grid-widget.component';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep, debounce, has, isNil } from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SelectAllHeaderComponent } from '../select-all-header/select-all-header.component';
import { StatusBarCountComponent } from '../status-bar-count/status-bar-count.component';

class GridTabInfinityScrollConstant {
  static DEFAULT_MAXIMUM_NUMBER_LINES = 10;
  static readonly DEFAULT_EMPTY_HEIGHT = '165px';
  static readonly DEFAULT_EMPTY_HEIGHT_WITH_PINNED_COLS = '185px';
  static readonly ROW_PAGINATOR_HEIGHT = 28;
}

@Component({
  selector: 'iu-grid-tab-infinity-scroll-ui',
  templateUrl: './grid-tab-infinity-scroll-ui.component.html',
  styleUrls: ['./grid-tab-infinity-scroll-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridTabInfinityScrollUiComponent
  extends AbstractDynamicComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() isTabTopLevel: boolean;
  @Input() isSearch = false;
  @Input() isChangeLog = false;
  @Input() rowSelection: RowSelectionType = 'multiple';
  rowMultiSelectWithCLick = false;
  // détermine de quelle type de fenetre vient cette grid
  @Input() windowType = IupicsMenuType.WINDOW;
  /*Indique si c'est l'universalfilter qui met à jour le model */
  fromUFData: {
    forceSort: boolean;
    forceFilter: boolean;
    forceGroup: boolean;
    forceValueCols: boolean;
    forcePivotCols: boolean;
    forcePivotMode: boolean;
    active?: boolean;
  } = {
    forceSort: false,
    forceFilter: false,
    forceGroup: false,
    forceValueCols: false,
    forcePivotCols: false,
    forcePivotMode: false,
  };
  forceCallWs = true;
  components: any;
  statusBar: any;
  columns: IupicsTableDataHeader[];
  columnsTableHeader;

  sideBar: SideBarDef = {
    toolPanels: ['columns'],
  };
  tableWidth = '100%';
  @Input() overridedTableHeight = false;
  @Input() tableHeight: string;
  tableHeightBackup: string;
  calculeTableHeight = this.getConstant('DEFAULT_CALCULE_TABLE_HEIGHT');
  isOneColumn: boolean;
  dataStoreKeys = {};
  pinnedBottomRow: any;
  @Input() query: SpecificGridQuery;
  @Input() initRequest: CompiereDataGridRequestJSON;
  dataSourceCreated = false;
  shouldChangePage = false;
  gatheredComplexDatas = {};
  @Output() multiSelectEmitter = new EventEmitter<{
    selected: boolean;
    data: {};
  }>();
  @Output() searchEmitter = new EventEmitter<boolean>();
  @Output() setFilterEmitter = new EventEmitter<CompiereDataGridRequestJSON>();
  @Output() gridPrefLoaded = new EventEmitter();
  @Output() gridViewCellClicked = new EventEmitter<any>();
  @Output() notifyGridTabAfterViewInitEmitter = new EventEmitter<any>();
  @Output() gridRefreshed: EventEmitter<DataStoreRequest> = new EventEmitter();
  @Output() gridCellEditingStopped: EventEmitter<DataStoreRequest> =
    new EventEmitter();
  @Input() dataSource: IServerSideDatasource;
  isDisplayFilter = false;
  maxConcurrentDatasourceRequests = 2;
  cacheOverflowSize = 2; // Number of line view when you scroll at bottom
  paginationPageSize: number;
  cacheBlockSize: number; // Number row retrieve per Block
  // lastGetRowsParams: { dataStoreRequest: DataStoreRequest; params: IServerSideGetRowsParams };
  @Input() sortModel: CompiereDataGridSortModel[] = [];
  @Input() filterModel: { [key: string]: CompiereDataGridFilterModel };
  @Input() groupColumn: CompiereDataGridGroupModel[] = [];
  @Input() valueCols: CompiereDataGridGroupModel[] = [];
  @Input() pivotCols: CompiereDataGridGroupModel[] = [];
  @Input() pivotMode = false;
  @Input() validation: string;
  @Input() initFromSwitch = false;
  @Input() shouldClearFilters = true;
  @Input() shouldClearSorts = true;
  @Input() shouldClearGroups = true;

  @Input() suppressRowClickSelection = false;
  @Input() rowClassRules: any;

  currentSelectIndex = -1;
  currentSelectPageIndex = 0;
  pinnedColKeys: string[];
  localeText: any;
  selectFirst = false;
  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;
  @Input() dataStored: DataStore;

  tempDataItems = [];

  parent_constraint: string = null;
  validation_sql: string = null;
  @Input() hasCheckBox = true;
  @Input() parentComponent: any;
  getContextMenuItems: GetContextMenuItems =
    this._getContextMenuItems.bind(this);
  private currentStateRequest: CompiereDataGridRequestJSON;

  private isGetRowsInProgress = false;
  defaultColDef = {
    filter: true,
    sortable: true,
    resizable: false,
  };
  defaultColGroupDef = {
    filter: true,
    sortable: true,
    resizable: false,
  };
  public modules: Module[] = AllModules;
  gridPreference: GridPreference;
  protected expandedColumnState: ColumnState[];
  protected reducedColumnState: ColumnState[];
  private subscriptionsGetRows: Map<string, Subscription> = new Map();
  isMobile = Global.isMobile();

  get isInSpecificOrNotLvl0(): boolean {
    return has(this.data, 'AD_FormDetail_ID') || !this.isTabTopLevel;
  }

  @Input() forcePaginationAutoPageSize = false;

  @Input() get canSelectAll(): boolean {
    if (has(this.data, 'canSelectAll')) {
      return this.data['canSelectAll'];
    }
    return true;
  }

  columnDatas;

  private preventGetRows = false;
  private preventOnRowSelectEventNodes = [];

  constructor(
    private windowFactory: WindowFactoryService,
    private dataStore: DataStoreService,
    private translate: TranslateService,
    private uiCreatorService: UICreatorService,
    private connectorService: SecurityManagerService,
    private config: AppConfig,
    private exportDataService: CompiereExportDataService,
    private printReportService: PrintReportService,
    private messageManager: MessageManagerService,
    private gridPreferencesService: GridPreferencesService
  ) {
    super();
    this.cacheBlockSize = this.config.getConstant(
      'GridTabInfinityScrollUiComponent#cacheBlockSize'
    );
    GridTabInfinityScrollConstant.DEFAULT_MAXIMUM_NUMBER_LINES =
      this.config.getConstant(
        'GridTabInfinityScrollUiComponent#Maximum_Number_Lines'
      )
        ? this.config.getConstant(
            'GridTabInfinityScrollUiComponent#Maximum_Number_Lines'
          )
        : 10;
    this.paginationPageSize =
      GridTabInfinityScrollConstant.DEFAULT_MAXIMUM_NUMBER_LINES;
    this.gridToolsInternationalization();
    this.updateColumnState = debounce(this.updateColumnState, 500);
  }

  ngOnInit() {
    this.subscriptions.push(
      (this.tabId
        ? this.uiCreatorService.getColumnInfos(
            this.tabId,
            has(this.data, 'AD_FormDetail_ID')
          )
        : of([])
      ).subscribe((cis) => {
        this.columnDatas = cis
          .filter(
            (ci) =>
              ((ci.fieldEntity.field.Name !== null &&
                ci.fieldEntity.field.Name !== undefined &&
                ci.fieldEntity.field.Name.trim() !== '') ||
                (ci.fieldEntity.field.name !== null &&
                  ci.fieldEntity.field.name !== undefined &&
                  ci.fieldEntity.field.name.trim() !== '')) &&
              (ci.fieldEntity.field.AD_Field_ID === -2 ||
                ci.fieldEntity.field.IsDisplayed ||
                ci.fieldEntity.field.IsKey)
          )
          .map((ci) => {
            return {
              data: ci.fieldEntity.field,
              details: ci.fieldEntity.details,
            };
          });
        this.paginationPageSize = this.getConstant(
          'DEFAULT_MAXIMUM_NUMBER_LINES'
        );
        this.rowMultiSelectWithCLick = this.windowType === IupicsMenuType.FORM;
        moment.locale(
          this.connectorService.getIupicsDefaultLanguage().iso_code
        );
        if (this.data.urlSearch) {
          this.isSearch = true;
        }
        let clonedTableHeaders;
        if (this.data && this.data.columnsTableHeader) {
          clonedTableHeaders = [...this.data.columnsTableHeader];
          // change columnHeader depending on issotrx
          //  if (this.data.columnsDetails )
          if (this.data.columnsDetails) {
            const ctx = this.getCurrentContext();
            if (
              ctx &&
              ctx['IsSOTrx'] !== null &&
              ctx['IsSOTrx'] !== undefined &&
              ctx['IsSOTrx'] === 'N'
            ) {
              clonedTableHeaders.map((colHeader: ColDef) => {
                if (this.data.columnsDetails.has(colHeader.field)) {
                  const colDetail = this.data.columnsDetails.get(
                    colHeader.field
                  );
                  if (colDetail && colDetail.field && colDetail.field.poName) {
                    colHeader.headerName = colDetail.field.poName;
                  }
                }
                colHeader.valueFormatter = this.columnFormatter.bind(this);
                return colHeader;
              });
            }
          }
        }
        if (this.isSearch) {
          this.isTabTopLevel = false;
          this.columns = [];
          if (clonedTableHeaders) {
            this.columns = [...clonedTableHeaders];
            this.columns.splice(0, 1);
          } else {
            if (this.data.searchColumns) {
              this.data.searchColumns.forEach((el) => {
                this.columns.push(
                  UICreatorUtils.transformFieldForGrid(el.field, true)
                );
              });
              this.data.hasCheckbox = false;
            }
          }
          if (!this.container && this.data['container']) {
            this.container = this.data['container'];
          }
        } else {
          this.columns = [...clonedTableHeaders];
        }
        if (this.columns[0] && !this.columns[0].checkboxSelection) {
          this.columns.unshift({
            headerComponentParams: {
              selectAll: () => this.setAllNodesSelection(true),
              deselectAll: () => this.setAllNodesSelection(false),
            },
            headerComponentFramework: this.canSelectAll
              ? SelectAllHeaderComponent
              : undefined,
            field: 'Data_UUID',
            headerName: '',
            filter: false,
            suppressMenu: true,
            sortable: true,
            width: 38,
            resizable: false,
            suppressSizeToFit: true,
            hide:
              this.data.hasCheckbox !== undefined
                ? !this.data.hasCheckbox
                : this.hasCheckBox
                ? false
                : true,
          });
          this.statusBar = {
            statusPanels: [
              {
                statusPanel: 'statusBarCountComponent',
                align: 'left',
                statusPanelParams: {
                  gridTab: this,
                },
              },
            ],
          };
          this.components = {
            statusBarCountComponent: StatusBarCountComponent,
          };
          if (this.data && this.data.frameworkComponents) {
            this.data.frameworkComponents['statusBarCountComponent'] =
              StatusBarCountComponent;
          } else {
            this.data.frameworkComponents = {
              statusBarCountComponent: StatusBarCountComponent,
            };
          }
          this.columns[0].checkboxSelection = (params) => {
            if (!params.node.group) {
              return this.data.hasCheckbox !== undefined
                ? this.data.hasCheckbox
                : this.hasCheckBox
                ? true
                : false;
            }
          };
        } else {
          this.columns[0].hide = false;
        }
        this.columns.forEach((column) => {
          column.sortable = true;
          column.resizable = true;
          column.filter = false;
          column.valueFormatter = this.columnFormatter.bind(this);
          column.comparator = function (a, b) {
            let valueA = '';
            let valueB = '';
            if (a instanceof Object) {
              valueA += a.displayValue.toLowerCase();
              valueB += b.displayValue.toLowerCase();
            } else {
              valueA += a;
              valueB += b;
            }
            valueA = valueA.toLowerCase();
            valueB = valueB.toLowerCase();
            if (valueA === valueB) {
              return 0;
            } else if (valueA > valueB) {
              return 1;
            } else {
              return -1;
            }
          };
          if (column.field === 'Data_UUID') {
            return true;
          } else if (column.filterParams) {
            column.filterParams.textFormatter = function (r) {
              if (r === null) {
                return null;
              } else {
                if (r instanceof Object) {
                  r = r.displayValue;
                }
                r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
                r = r.replace(new RegExp('æ', 'g'), 'ae');
                r = r.replace(new RegExp('ç', 'g'), 'c');
                r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
                r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
                r = r.replace(new RegExp('ñ', 'g'), 'n');
                r = r.replace(new RegExp('[òóôõøö]', 'g'), 'o');
                r = r.replace(new RegExp('œ', 'g'), 'oe');
                r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
                r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
              }
              return r;
            };

            column.filterParams.textCustomComparator = function (
              filter: string,
              gridValue: any,
              filterText: string
            ) {
              const filterTextLoweCase = filterText.toLowerCase();
              const valueLowerCase = gridValue.toString().toLowerCase();
              switch (filter) {
                case 'contains':
                  return valueLowerCase.indexOf(filterTextLoweCase) >= 0;
                case 'notContains':
                  return valueLowerCase.indexOf(filterTextLoweCase) === -1;
                case 'equals':
                  return valueLowerCase === filterTextLoweCase;
                case 'notEqual':
                  return valueLowerCase !== filterTextLoweCase;
                case 'startsWith':
                  return valueLowerCase.indexOf(filterTextLoweCase) === 0;
                case 'endsWith':
                  const index = valueLowerCase.lastIndexOf(filterTextLoweCase);
                  return (
                    index >= 0 &&
                    index === valueLowerCase.length - filterTextLoweCase.length
                  );
              }
            };
          }
          if (
            this.data &&
            this.data.editorColumns &&
            this.data.editorColumns.length > 0 &&
            this.data.editorColumns.indexOf(column.field) > -1
          ) {
            // column.sortable = false;
            column.enablePivot = false;
            column.enableRowGroup = false;
          }
        });
        const columnsFiltered = this.getColumnsDisplayLogic(this.columns);
        this.columnsTableHeader = columnsFiltered;
        if (<GridViewUiComponent>this.DOMParentComponent && columnsFiltered) {
          (<GridViewUiComponent>this.DOMParentComponent).columnNames =
            columnsFiltered
              .filter((col) => col.field !== 'Data_UUID')
              .map((column) => {
                return { id: column.field, displayValue: column.headerName };
              });
        }

        this.data.items = [];
        this.subscriptions.push(
          this.componentEmitter.subscribe((event) => {
            this.windowFactory.newEventHandler(event);
          })
        );

        if (!this.overridedTableHeight) {
          if (this.isTabTopLevel) {
            this.tableHeight = this.config.getConstant(
              'GridTabInfinityScrollUiComponent#tableHeight_isTabTopLevel'
            );
          } else {
            this.calculeTableHeight = this.getConstant(
              'DEFAULT_CALCULE_TABLE_HEIGHT'
            );
            if (this.isSearch) {
              this.tableWidth = this.config.getConstant(
                'GridTabInfinityScrollUiComponent#tableWidth_isSearch'
              );
              this.tableHeight = this.config.getConstant(
                'GridTabInfinityScrollUiComponent#tableHeight_isSearch'
              );
            }
          }
        }
      })
    );
  }

  setAllNodesSelection(selected: boolean) {
    if (this.agGrid) {
      //https://stackoverflow.com/questions/54779821/row-selection-using-foreachnode-very-slow
      this.preventOnRowSelectEventNodes = [];
      this.agGrid.api.forEachNode((node) => {
        this.preventOnRowSelectEventNodes.push(node);
      });
      if (this.preventOnRowSelectEventNodes.length > 0) {
        for (let i = 0; i < this.preventOnRowSelectEventNodes.length - 1; i++) {
          if (
            (this.preventOnRowSelectEventNodes[i].isSelected() && !selected) ||
            (!this.preventOnRowSelectEventNodes[i].isSelected() && selected)
          ) {
            this.preventOnRowSelectEventNodes[i].setSelected(selected);
          }
        }
        this.preventOnRowSelectEventNodes[
          this.preventOnRowSelectEventNodes.length - 1
        ].setSelected(selected);
      }
    }
  }

  ngAfterViewInit() {
    this.sortModel.forEach((column) => {
      this.agGrid.columnApi.getColumn(column.colId).setSort(column.sort);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.cancelRequests();
    this.agGrid = undefined;
  }

  private cancelRequests() {
    let sub: IteratorResult<Subscription, Subscription>;
    const it = this.subscriptionsGetRows.values();
    while (!(sub = it.next()).done) {
      sub.value?.unsubscribe();
    }
  }

  createServerDataSource(currentData?: any, indexEditViewToEdit?: number) {
    this.filterModel = !this.filterModel ? {} : this.filterModel;
    this.sortModel = !this.sortModel ? [] : this.sortModel;
    this.groupColumn = !this.groupColumn ? [] : this.groupColumn;
    let fromCreated = true;
    this.dataSourceCreated = false;
    let isDisplayed = true;
    let parentDataStore: DataStore;
    let isNewRecord = false;
    let recordChanged = false;
    if (!this.isTabTopLevel) {
      const grid = <GridViewUiComponent>this.DOMParentComponent;
      if (grid && grid.parentTab && grid.editViewParent) {
        parentDataStore = this.getParentStore();
        isDisplayed = grid.updateDisplayLogic();
        if (isDisplayed) {
          currentData = currentData ? currentData : this.getCurrentContext();
          const previousParent_constraint = this.parent_constraint;
          isNewRecord = previousParent_constraint
            ? previousParent_constraint.split('=').length > 1
              ? false
              : true
            : true;
          this.parent_constraint = '';
          const filterToAdds = [];
          for (let i = 0; i < this.gridTabFilter.length; i++) {
            const _gridTabFilter = this.gridTabFilter[i];
            const filterToAdd = LogicEvaluator.parseLogic(
              currentData,
              _gridTabFilter,
              this.connectorService.getIupicsUserAccount()
            );
            if (filterToAdd && filterToAdd.length > 0) {
              filterToAdds.push(filterToAdd);
            }
          }
          this.parent_constraint = filterToAdds
            .map((filterToAdd) => filterToAdd.replace('=', ','))
            .join(',');
          if (this.parent_constraint !== previousParent_constraint) {
            recordChanged = true;
          }
        }
      } else if (this.isSearch) {
        this.onGridReadySearch();
      }
    } else {
      if (this.gridTabFilter && this.gridTabFilter.length > 0) {
        this.parent_constraint = this.gridTabFilter[0];
      }
      if (this.gridTabValidator && this.gridTabValidator.length > 0) {
        this.validation_sql = this.gridTabValidator[0];
      }
    }
    if (isDisplayed) {
      this.agGrid.api.closeToolPanel();
      this.dataSource = {
        getRows: (params) => {
          if (this.preventGetRows) {
            this.preventGetRows = false;
            return;
          }
          const propEditedByAggrid = this.getAggridSourceOfRequest(
            params.request
          );
          let modifyUniversalFilter = propEditedByAggrid ? true : false;
          if (
            parentDataStore &&
            parentDataStore.status === DataStoreStatus.NEWRECORD
          ) {
            // params.successCallback([], 0);
            params.success({ rowData: [], rowCount: 0 });
            return;
          }
          // * Ce bout de code est utilisé lorsque l'on réalise des actions de tris directement sur l'ag-grid
          if (!this.initFromSwitch) {
            if (!this.fromUFData.forceSort && params.request.sortModel) {
              if (this.sortModel.length !== params.request.sortModel.length) {
                this.sortModel = [];
                modifyUniversalFilter = true;
              }
              for (let i = 0; i < params.request.sortModel.length; i++) {
                const sortColumn = params.request.sortModel[i];
                const col = this.sortModel.find(
                  (colFinded) => colFinded.colId === sortColumn.colId
                );
                if (!col || (col && col.sort !== sortColumn.sort)) {
                  this.sortModel = params.request.sortModel;
                  modifyUniversalFilter = true;
                  break;
                }
              }
            }
            if (!this.fromUFData.forceGroup && params.request.rowGroupCols) {
              if (
                this.groupColumn.length !== params.request.rowGroupCols.length
              ) {
                this.groupColumn = [];
                modifyUniversalFilter = true;
              }
              for (let i = 0; i < params.request.rowGroupCols.length; i++) {
                const groupColumn = params.request.rowGroupCols[i];
                const col = this.groupColumn.find(
                  (colFinded) => colFinded.field === groupColumn.field
                );
                if (!col) {
                  this.groupColumn = params.request
                    .rowGroupCols as CompiereDataGridGroupModel[];
                  modifyUniversalFilter = true;
                  break;
                }
              }
            }
            if (!this.fromUFData.forceValueCols && params.request.valueCols) {
              if (this.valueCols.length !== params.request.valueCols.length) {
                this.valueCols = [];
              }
              for (let i = 0; i < params.request.valueCols.length; i++) {
                const valueCol = params.request.valueCols[i];
                const col = this.valueCols.find(
                  (colFinded) => colFinded.field === valueCol.field
                );
                if (!col || (col && col.aggFunc !== valueCol.aggFunc)) {
                  this.valueCols = params.request
                    .valueCols as CompiereDataGridGroupModel[];
                  modifyUniversalFilter = true;
                  break;
                }
              }
            }
            if (!this.fromUFData.forcePivotCols && params.request.pivotCols) {
              if (this.pivotCols.length !== params.request.pivotCols.length) {
                this.pivotCols = [];
              }
              for (let i = 0; i < params.request.pivotCols.length; i++) {
                const pivotCol = params.request.pivotCols[i];
                const col = this.pivotCols.find(
                  (colFinded) => colFinded.field === pivotCol.field
                );
                if (!col) {
                  this.pivotCols = params.request
                    .pivotCols as CompiereDataGridGroupModel[];
                  modifyUniversalFilter = true;
                  break;
                }
              }
            }
            if (propEditedByAggrid) {
              if (
                !this.fromUFData.forcePivotMode &&
                params.request.pivotMode != this.pivotMode
              ) {
                this.pivotMode = params.request.pivotMode;
                this.DOMParentComponent.filter.pivotMode = this.pivotMode;
                modifyUniversalFilter = true;
              }
            }
          } else {
            this.initFromSwitch = !this.initFromSwitch;
          }
          // * appliquer des querys venant de l'url
          if (this.initRequest) {
            if (this.initRequest.filterModel) {
              this.filterModel = { ...this.initRequest.filterModel };
              modifyUniversalFilter = true;
            }
            if (this.initRequest.sortModel) {
              this.sortModel = [...this.initRequest.sortModel];
              modifyUniversalFilter = true;
            }
            if (this.initRequest.rowGroupCols) {
              this.groupColumn = [...this.initRequest.rowGroupCols];
              modifyUniversalFilter = true;
            }
            if (this.initRequest.valueCols) {
              this.valueCols = [...this.initRequest.valueCols];
              modifyUniversalFilter = true;
            }
            if (this.initRequest.pivotCols) {
              this.pivotCols = [...this.initRequest.pivotCols];
              modifyUniversalFilter = true;
            }
            if (
              this.initRequest.pivotMode != null &&
              this.initRequest.pivotMode != undefined
            ) {
              this.pivotMode = this.initRequest.pivotMode;
              modifyUniversalFilter = true;
            }
            if (this.initRequest.validation) {
              this.validation = this.validation
                ? this.validation + ' and ' + this.initRequest.validation
                : this.initRequest.validation;
            }
            this.initRequest = undefined;
          }

          let windowType;
          let entityId;
          let searchEntityType;
          let searchKeyColumn;
          let validationCode;
          let query: SpecificGridQuery;
          if (this.data.validationCode) {
            validationCode = LogicEvaluator.replaceVariables(
              this.data.validationCode,
              this.connectorService.getIupicsUserContext(),
              this.getCurrentContext()
            );
          }
          if (this.isSearch) {
            query = this.query;
            windowType = CompiereDataGridType.SEARCH_WINDOW;
            if (this.data.validationCode) {
              validationCode = LogicEvaluator.replaceVariables(
                this.data.validationCode,
                this.connectorService.getIupicsUserContext(),
                this.getCurrentContext()
              );
            }
            switch (this.windowType) {
              case IupicsMenuType.WINDOW:
                windowType = CompiereDataGridType.SEARCH_WINDOW;
                entityId = this.data.details.entityID;
                searchEntityType = this.data.details.entityType;
                searchKeyColumn = this.data.details.keyColumn;
                break;
              case IupicsMenuType.PROCESS:
                windowType = CompiereDataGridType.SEARCH_WINDOW;
                entityId = this.data.details.entityID;
                searchEntityType = this.data.details.entityType;
                searchKeyColumn = this.data.details.keyColumn;
                break;
              case IupicsMenuType.FORM:
                windowType = CompiereDataGridType.FORM;
                entityId = this.data['AD_FormDetail_ID'];
                break;
              default:
                windowType = CompiereDataGridType.SEARCH_WINDOW;
                entityId = this.data.details.entityID;
                searchEntityType = this.data.details.entityType;
                searchKeyColumn = this.data.details.keyColumn;
                break;
            }
          } else {
            windowType = CompiereDataGridType.WINDOW;
            entityId = this.tabId;
          }
          const windowId =
            this.windowType === IupicsMenuType.WINDOW
              ? this.data.fieldId
              : this.data.columnId;
          const dataStoreRequest: DataStoreRequest = {
            windowId: this.container ? this.data.AD_window_ID : windowId,
            compiereRequest: {
              windowType: windowType,
              entityId: entityId,
              startRow: params.request.startRow,
              endRow: params.request.endRow,
            },
          };
          if (this.isSearch) {
            dataStoreRequest.compiereRequest.headerCols =
              this.getColumnsDisplayLogic(this.columns)
                .filter((c) => c.field !== 'Data_UUID')
                .map(
                  (col) =>
                    ({
                      id: col.field,
                      field: col.field,
                      displayName: col.headerName,
                      valueFormatter: this.columnFormatter.bind(this),
                    } as CompiereDataGridGroupModel)
                );
          }
          if (
            (params.request.pivotMode ||
              this.windowType === IupicsMenuType.FORM) &&
            dataStoreRequest.compiereRequest.hasOwnProperty('headerCols')
          ) {
            delete dataStoreRequest.compiereRequest.headerCols;
          }
          if (this.parent_constraint) {
            // On ajoute la contrainte du parent si il y en a une
            dataStoreRequest.parent_constraint = this.parent_constraint;
          }
          dataStoreRequest.compiereRequest = Object.assign(
            dataStoreRequest.compiereRequest,
            params.request
          );
          if (this.sortModel) {
            // On ajoute le tri si il y en a un
            dataStoreRequest.compiereRequest.sortModel = this.sortModel;
          }
          if (this.filterModel && Object.keys(this.filterModel).length > 0) {
            dataStoreRequest.compiereRequest.filterModel = this.filterModel;
          }
          if (this.groupColumn) {
            dataStoreRequest.compiereRequest.rowGroupCols = this.groupColumn;
          }
          if (this.valueCols) {
            dataStoreRequest.compiereRequest.valueCols = this.valueCols;
          }
          if (this.pivotCols) {
            dataStoreRequest.compiereRequest.pivotCols = this.pivotCols;
          }
          if (this.pivotMode) {
            dataStoreRequest.compiereRequest.pivotMode = this.pivotMode;
          }
          if (this.validation) {
            // On ajoute la validation si il y en déjà a une
            dataStoreRequest.compiereRequest.validation = this.validation;
          }
          if (
            !this.isTabTopLevel &&
            this.gridTabValidator &&
            this.gridTabValidator.length > 0
          ) {
            this.validation_sql = LogicEvaluator.replaceVariables(
              this.gridTabValidator[0],
              this.connectorService.getIupicsUserContext(),
              this.getCurrentContext()
            );
            (<GridViewUiComponent>this.DOMParentComponent).mapValidator =
              this.validation_sql;
          }
          if (this.validation_sql) {
            // On ajoute la validation sql si il y en a une
            dataStoreRequest.compiereRequest.validation = this.validation_sql;
          }
          if (validationCode && this.windowType !== IupicsMenuType.FORM) {
            dataStoreRequest.compiereRequest.validation = dataStoreRequest
              .compiereRequest.validation
              ? dataStoreRequest.compiereRequest.validation +
                ' and ' +
                validationCode
              : validationCode;
          }
          dataStoreRequest.compiereRequest.searchEntityType = searchEntityType;
          dataStoreRequest.compiereRequest.searchKeyColumn = searchKeyColumn;
          if (this.DOMParentComponent) {
            dataStoreRequest.compiereRequest.parent_formDetail_id = (<
              GridViewUiComponent
            >this.DOMParentComponent).parentFormID;
          }
          // query des forms
          if (query) {
            if (
              query.specificGridOptions &&
              query.specificGridOptions.shouldResetRequest === true
            ) {
              if (dataStoreRequest && dataStoreRequest.compiereRequest) {
                dataStoreRequest.compiereRequest.filterModel = {};
              }
            }
            if (
              this.createFilterModelFromForm(
                query,
                dataStoreRequest,
                propEditedByAggrid ? true : false
              )
            ) {
              modifyUniversalFilter = true;
            }
          }
          // Fin de l'impact de l'universalFilter
          if (
            <GridViewUiComponent>this.DOMParentComponent &&
            (modifyUniversalFilter || fromCreated)
          ) {
            fromCreated = false;
            this.setFilterEmitter.emit(dataStoreRequest.compiereRequest);
            return;
          }
          if (
            dataStoreRequest.compiereRequest.groupKeys.length ===
            dataStoreRequest.compiereRequest.rowGroupCols.length
          ) {
            dataStoreRequest.compiereRequest.valueCols = [];
          }
          this.getRows(
            dataStoreRequest,
            params,
            isNewRecord,
            indexEditViewToEdit,
            recordChanged,
            null
          );
        },
      };
      if (
        !this.dataSourceCreated &&
        this.windowType !== IupicsMenuType.FORM &&
        this.windowType !== IupicsMenuType.INFO_WINDOW
      ) {
        if (<GridViewUiComponent>this.DOMParentComponent) {
          if (
            (<GridViewUiComponent>this.DOMParentComponent).isLaunchSearchGrid
          ) {
            this.agGrid.api.setServerSideDatasource(this.dataSource);
            this.dataSourceCreated = true;
          }
        } else {
          this.agGrid.api.setServerSideDatasource(this.dataSource);
          this.dataSourceCreated = true;
        }
      } else {
        if (
          this.windowType === IupicsMenuType.FORM ||
          this.windowType === IupicsMenuType.INFO_WINDOW
        ) {
          if (this.notifyGridTabAfterViewInitEmitter.observers.length > 0) {
            // settimeout pour etre sur qu'on emit au moment ou le gridtab est init el famoso AfterAfterViewInit
            setTimeout(() => {
              this.notifyGridTabAfterViewInitEmitter.emit(this);
            }, 200);
          } else {
            // pour le cas des gridtab dans des forms défini dans la template html exemple=> tree view
            this.agGrid.api.setServerSideDatasource(this.dataSource);
            this.dataSourceCreated = true;
          }
        }
      }
    }
  }

  columnResize() {
    if (this.data && this.data.isFitResize && !Global.isMobileWidth()) {
      this.agGrid.api.sizeColumnsToFit();
    } else {
      if (this.data['autosizeAllColumns']) {
        this.agGrid.columnApi.autoSizeColumns(
          this.agGrid.columnApi.getAllColumns().map((col) => {
            return col.getColId();
          })
        );
      }
      if (this.groupColumn.length > 0) {
        // setTimeout(() => {
        this.agGrid.columnApi.autoSizeColumn(
          this.agGrid.columnApi.getAllGridColumns()[0].getColId()
        );
        // }, 0);
      }

      this.agGrid.columnApi.setColumnWidth(
        this.columnsTableHeader[0].field,
        38
      );
      // const columns = this.agGrid.columnApi.getColumnState();
      // for (let i = 1; i < columns.length; i++) {
      //   if (columns[i].width > 217) {
      //     this.agGrid.columnApi.setColumnWidth(columns[i].colId, 217, true);
      //   }
      // }
    }
    if (
      <GridViewUiComponent>this.DOMParentComponent &&
      (<GridViewUiComponent>this.DOMParentComponent).editViewParent &&
      this.DOMParentComponent.parentTab
    ) {
      // On check si le parent attends la réponse du resize pour scroll
      if (
        (<GridViewUiComponent>this.DOMParentComponent).editViewParent
          .isWaitingScroll
      ) {
        this.DOMParentComponent.parentTab.scrollTo();
      }
    }
  }
  refresh(removeDataFromStore = true, query?: SpecificGridQuery) {
    if (this.isGetRowsInProgress) {
      if (
        query &&
        query.specificGridOptions &&
        query.specificGridOptions.isFromInputLaunch
      ) {
        this.cancelRequests();
      } else {
        return;
      }
    }
    this.forceCallWs = removeDataFromStore;
    if (query) {
      this.query = query;
    }
    this.shouldChangePage = true;
    if (this.agGrid && this.agGrid.api) {
      this.currentSelectPageIndex = this.agGrid.api.paginationGetCurrentPage();
      this.agGrid.api.setServerSideDatasource(this.dataSource);
    }
  }

  onlyOneColumn(loadPref = true) {
    if (this.isTabTopLevel) {
      let columnsReduce = this.data.columnsReduceGrid;
      if (columnsReduce.length === 0) {
        columnsReduce = ['Value', 'Name', 'DocumentNo'];
      }
      this.agGrid.columnApi.setColumnsVisible(
        this.agGrid.columnApi.getAllGridColumns().filter((col) => {
          if (!columnsReduce.includes(col.getColDef().field)) {
            return col;
          }
        }),
        false
      );
      let sizeGrid = 23;
      let countCol = 0;
      this.agGrid.columnApi.getColumnState().forEach((col) => {
        if (!col.hide) {
          sizeGrid += col.width;
          countCol++;
        }
      });
      if (sizeGrid < 217) {
        if (countCol === 1) {
          this.agGrid.columnApi.getColumnState().forEach((col) => {
            if (!col.hide) {
              this.agGrid.columnApi.setColumnWidth(col.colId, 217);
            }
          });
        } else if (countCol === 2) {
          let sizefirst = 0;
          let first = true;

          this.agGrid.columnApi.getColumnState().forEach((col) => {
            if (!col.hide) {
              if (first) {
                first = false;
                sizefirst = col.width;
              } else {
                this.agGrid.columnApi.setColumnWidth(
                  col.colId,
                  217 - sizefirst
                );
              }
            }
          });
        } else if (this.columnsTableHeader[3]) {
          this.agGrid.columnApi.setColumnVisible(
            this.columnsTableHeader[3].field,
            true
          );
          this.agGrid.columnApi.setColumnWidth(
            this.columnsTableHeader[3].field,
            217
          );
        } else {
          if (this.columnsTableHeader.length === 2) {
            this.agGrid.columnApi.setColumnVisible(
              this.columnsTableHeader[1].field,
              true
            );
            this.agGrid.columnApi.setColumnWidth(
              this.columnsTableHeader[1].field,
              217
            );
          } else {
            for (let i = 1; i < this.columnsTableHeader.length; i++) {
              this.agGrid.columnApi.setColumnVisible(
                this.columnsTableHeader[i].field,
                true
              );
              this.agGrid.columnApi.setColumnWidth(
                this.columnsTableHeader[i].field,
                108
              );
            }
          }
        }
      }
    }
    // this.tableWidth = '100%';
    this.displayHideFilters(false);
    this.isOneColumn = true;
    // init du layout perso de l'utilisateur
    if (loadPref) {
      this.subscriptions.push(
        this.loadPreference(true).subscribe((loaded) => {})
      );
    }
  }

  allColumns(staySelect = true, selectable: boolean = true, size?: number) {
    if (!staySelect) {
      this.agGrid.api.deselectAll();
      (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId = null;
    }
    // init du layout perso de l'utilisateur
    this.subscriptions.push(
      this.loadPreference(true).subscribe((loaded) => {
        if (!loaded) {
          if (this.expandedColumnState) {
            this.agGrid.columnApi.applyColumnState({
              state: this.expandedColumnState,
              applyOrder: true,
            });
          } else {
            if (this.isTabTopLevel) {
              this.agGrid.columnApi
                .getAllGridColumns()
                .forEach((col) => col.setVisible(true));
              this.agGrid.api.refreshHeader();
              this.columnResize();
              this.tableWidth = '100%';
            } else {
              this.tableWidth =
                this.DOMParentComponent.data.colspan *
                  (this.container.editTabSize - 2.5) +
                'em';
            }
          }
          this.isOneColumn = !selectable;
        }
      })
    );
  }

  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}

  onGridReadySearch() {
    if (!this.overridedTableHeight) {
      this.tableHeight = this.getConstant('DEFAULT_MAX_TABLE_HEIGHT');
    }
    if (this.DOMParentComponent === undefined) {
      this.agGrid.api.setServerSideDatasource(this.dataSource);
    }
  }

  applyFilter(
    sortModel: CompiereDataGridSortModel[],
    filterModel: { [key: string]: CompiereDataGridFilterModel },
    rowGroupCols: CompiereDataGridGroupModel[],
    valueCols?: CompiereDataGridGroupModel[],
    pivotCols?: CompiereDataGridGroupModel[],
    pivotMode?: boolean
  ) {
    if (!this.agGrid) {
      return;
    }
    this.query = null;
    this.agGrid.api.deselectAll();
    this.fromUFData = {
      forceSort: false,
      forceFilter: false,
      forceGroup: false,
      forceValueCols: false,
      forcePivotCols: false,
      forcePivotMode: false,
    };
    const localfromUFData = {
      forceSort: false,
      forceFilter: false,
      forceGroup: false,
      forceValueCols: false,
      forcePivotCols: false,
    };
    const colStates = this.agGrid.columnApi.getColumnState();
    colStates.forEach((column) => {
      const pcFound = pivotCols.find((pc) => pc.field === column.colId);
      if (
        (pcFound && column.pivot !== true) ||
        (!pcFound && column.pivot !== false)
      ) {
        localfromUFData.forcePivotCols = true;
      }
      const vcFound = valueCols.find((vc) => vc.field === column.colId);
      if (
        (vcFound && column.aggFunc !== vcFound.aggFunc) ||
        (!vcFound && column.aggFunc !== null)
      ) {
        localfromUFData.forceValueCols = true;
      }
      const rcFound = rowGroupCols.find((rc) => rc.field === column.colId);
      if (
        (rcFound && column.rowGroup !== true) ||
        (!rcFound && column.rowGroup !== false)
      ) {
        localfromUFData.forceGroup = true;
      }
    });
    this.agGrid.columnApi.getAllColumns().forEach((column) => {
      column.setSort('');
    });
    if (sortModel) {
      if (this.agGrid.columnApi.getSecondaryColumns()) {
        this.agGrid.columnApi.getSecondaryColumns().forEach((column) => {
          column.setSort('');
        });
      }
      this.sortModel = sortModel;
      this.fromUFData.forceSort = true;
      this.sortModel.forEach((column) => {
        if (this.agGrid.columnApi.getColumn(column.colId)) {
          this.agGrid.columnApi.getColumn(column.colId).setSort(column.sort);
        } else if (
          this.agGrid.columnApi.isPivotMode() &&
          this.agGrid.columnApi.getSecondaryColumns()
        ) {
          const colFound = this.agGrid.columnApi
            .getSecondaryColumns()
            .find((secCol) => secCol.getColId() === column.colId);
          if (colFound) {
            colFound.setSort(column.sort);
          }
        }
      });
    }
    if (filterModel) {
      this.fromUFData.forceFilter = true;
      this.filterModel = filterModel;
    }
    if (localfromUFData.forceGroup) {
      this.groupColumn = rowGroupCols;
      this.preventGetRows = true;
      this.agGrid.columnApi.removeRowGroupColumns(
        colStates.map((c) => c.rowGroup && c.colId)
      );
      this.fromUFData.forceGroup = true;
      this.preventGetRows = true;
      this.agGrid.columnApi.addRowGroupColumns(
        this.groupColumn.map((c) => c.field)
      );
    }
    if (localfromUFData.forceValueCols) {
      this.preventGetRows = true;
      this.agGrid.columnApi.removeValueColumns(
        colStates.map((c) => Boolean(c.aggFunc) && c.colId)
      );
      this.valueCols = valueCols;
      this.fromUFData.forceValueCols = true;
      const columns = this.valueCols.map((valueCol) => {
        const column = this.agGrid.columnApi.getColumn(valueCol.field);
        column.setAggFunc(valueCol.aggFunc);
        return column;
      });
      this.preventGetRows = true;
      this.agGrid.columnApi.addValueColumns(columns);
    }
    if (localfromUFData.forcePivotCols) {
      this.preventGetRows = true;
      this.pivotCols = pivotCols;
      this.agGrid.columnApi.removePivotColumns(
        colStates.map((c) => c.pivot && c.colId)
      );
      this.fromUFData.forcePivotCols = true;
      this.preventGetRows = true;
      this.agGrid.columnApi.addPivotColumns(this.pivotCols.map((c) => c.field));
    }
    this.fromUFData.active = true;
    if (pivotMode != this.agGrid.columnApi.isPivotMode()) {
      this.fromUFData.forcePivotMode = true;
      this.pivotMode = pivotMode;
      this.agGrid.columnApi.setPivotMode(this.pivotMode);
    } else {
      this.preventGetRows = false;
      this.agGrid.api.setServerSideDatasource(this.dataSource);
    }
  }

  displayHideFilters(isDisplay: boolean) {
    this.isDisplayFilter = isDisplay;
    this.columnsTableHeader[0].hide = true;
    this.agGrid.api.refreshHeader();
  }
  getCurrentContext() {
    let editViewParent;
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getCurrentContext();
    } else {
      if (
        this.parentComponent &&
        this.parentComponent.editTabs &&
        this.parentComponent.editTabs[0]
      ) {
        editViewParent = this.parentComponent;
      }
      return EditViewUtils.getCurrentContext(
        editViewParent,
        this.dataStored ? this.dataStored : undefined,
        this.connectorService.getIupicsUserContext()
      );
    }
  }
  getParentStore() {
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getParentStore();
    } else {
      if (
        this.parentComponent &&
        this.parentComponent.editTabs &&
        this.parentComponent.editTabs[0]
      ) {
        return this.parentComponent.editTabs[0].dataStored;
      }
    }
  }
  /**
   * Permet de cacher les columns dont la displaylogic est validée
   */
  getColumnsDisplayLogic(cols: any[]) {
    cols = cols.map((col: ColDef) => {
      col.valueFormatter = this.columnFormatter.bind(this);
      return col;
    });
    if (
      this.data.columnsDisplayLogicMap &&
      this.data.columnsDisplayLogicMap.size > 0
    ) {
      return cols.filter((col) => {
        let returnCol = null;
        const displayLogic = this.data.columnsDisplayLogicMap.get(col.field);
        if (this.data.hiddenColumns !== undefined) {
          if (!this.data.hiddenColumns.includes(col.field)) {
            if (
              displayLogic === undefined ||
              displayLogic === null ||
              displayLogic === '' ||
              LogicEvaluator.evaluateLogic(
                this.getCurrentContext(),
                displayLogic
              )
            ) {
              returnCol = col;
            }
          } else {
            col.hide = true;
            returnCol = col;
          }
          return returnCol;
        } else {
          if (
            displayLogic === undefined ||
            displayLogic === null ||
            displayLogic === ''
          ) {
            returnCol = col;
          } else if (
            LogicEvaluator.evaluateLogic(this.getCurrentContext(), displayLogic)
          ) {
            returnCol = col;
          }
          return returnCol;
        }
      });
    } else {
      return cols;
    }
  }

  onRowSelected(event: RowSelectedEvent) {
    // if (
    //   event.target.tagName === 'SPAN' ||
    //   (this.windowType === IupicsMenuType.FORM && event.target.tagName === 'DIV' && event.target.firstElementChild === null)
    // ) {
    let eventShouldTrigger = true;
    const index = this.preventOnRowSelectEventNodes.findIndex(
      (n) => n.id === event.node.id
    );
    if (index > -1) {
      this.preventOnRowSelectEventNodes.splice(index, 1);
      eventShouldTrigger = this.preventOnRowSelectEventNodes.length == 0;
    }
    if (eventShouldTrigger) {
      this.multiSelectEmitter.emit({
        selected: this.agGrid.api.getSelectedRows().length > 0,
        data: this.agGrid.api.getSelectedRows(),
      });
      this.agGrid.api.refreshHeader();
    }
    // }
  }

  onCellClicked(event) {
    if (!event.node.group) {
      if (event.origin && event.origin === 'edit') {
        this.agGrid.api.deselectAll();
        event.node.setSelected(true);
      }
      this.currentSelectIndex = event.node.rowIndex;
      if (
        event.colDef.field !== 'Data_UUID' ||
        this.windowType === IupicsMenuType.FORM
      ) {
        if (!this.isSearch) {
          const windowId = this.data.AD_window_ID;
          const dataStoreKey = this.dataStore.generateDataStoreKey(
            windowId,
            this.tabId,
            event.data['Data_UUID'],
            this.parent_constraint
          );
          if (this.DOMParentComponent) {
            (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId =
              event.data['Data_UUID'];
            (<GridViewUiComponent | GridWidgetComponent>(
              this.DOMParentComponent
            )).onRowSelectedOnGridTab(dataStoreKey, this.isOneColumn);
          }
        } else if (
          this.windowType === IupicsMenuType.FORM &&
          this.data.editorColumns &&
          this.data.editorColumns.length > 0 &&
          this.data.editorColumns.indexOf(event.colDef.field) >= 0
        ) {
          // * lfi: je ne sais pas ce que ça fait du coup je ne le supprime pas au cas où c'était utile
          // * mais en tout cas ça cassait le comportement de cell click avec suppressRowClickSelection
          // this.agGrid.api.forEachNode(function(node) {
          //   if (event.rowIndex === parseInt(node.id, 10) && !event.node.selected) {
          //     node.setSelected(true);
          //   }
          // });
          this.gridViewCellClicked.emit(event);
        } else {
          if (this.dataStored) {
            // classic search panel
            this.dataStored.addContextData(
              event.data,
              this.config.getConstant('ContextPrefixSearchWindow')
            );
          }
          this.searchEmitter.emit(event);
        }
      } else {
        this.multiSelectEmitter.emit({
          selected: true,
          data: this.agGrid.api.getSelectedRows(),
        });
        this.agGrid.api.refreshHeader();
      }
    }
  }

  getKeysAndDataArray(datas: any): any[] {
    const dataArray = [];
    const i = 0;
    for (const key in datas) {
      if (datas[key]) {
        if (datas.hasOwnProperty(key)) {
          dataArray[datas[key].seqNo] = datas[key].data;
          this.dataStoreKeys[key] = datas[key].key;
        }
      }
    }
    return dataArray.filter((n) => {
      return n !== undefined;
    });
  }

  getKeysAndDataSearch(datas: any): any[] {
    const dataArray = [];
    if (this.columnsTableHeader && datas) {
      for (let i = 0; i < datas.length; i++) {
        const dataTransformed = {};
        for (let j = 0; j < datas[i].length; j++) {
          dataTransformed[this.columnsTableHeader[j].field] = datas[i][j];
        }
        dataArray.push(dataTransformed);
      }
    }
    return dataArray.filter((n) => {
      return n !== undefined;
    });
  }

  openEmptyEditView() {
    (<GridViewUiComponent>this.DOMParentComponent).openNew(this.isOneColumn);
  }

  selectFirstLine(data) {
    const windowId = this.data.AD_window_ID;
    const dataStoreKey = this.dataStore.generateDataStoreKey(
      windowId,
      this.tabId,
      this.dataStore.getRecordIdString(data['Data_UUID']),
      this.parent_constraint
    );
    if (this.dataStore.findInCurrentStore(dataStoreKey)) {
      (<GridViewUiComponent>this.DOMParentComponent).onRowSelectedOnGridTab(
        dataStoreKey,
        this.isOneColumn
      );
      setTimeout(() => {
        const firstNode = this.agGrid.api.getDisplayedRowAtIndex(0);
        this.agGrid.api.deselectAll();
        if (firstNode) {
          firstNode.setSelected(true);
        }
        this.currentSelectIndex = 0;
      }, 10);
    }
  }
  /**
   *
   * @param castPageChange true si redirection vers bonne page
   */
  selectLastLineSelected(castPageChange: boolean = true) {
    if (
      this.agGrid &&
      <GridViewUiComponent>this.DOMParentComponent &&
      (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId
    ) {
      this.setSelectedRow(
        (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId,
        castPageChange
      );
    } else if (this.agGrid && castPageChange) {
      this.agGrid.api.paginationGoToPage(this.currentSelectPageIndex);
    }
  }
  getAllValuesOfColumn(agGrid: AgGridAngular, columnName) {
    const rowValues = [];
    agGrid.api.forEachNode((node) => {
      rowValues.push(node.data[columnName]);
    });
    return rowValues;
  }
  createPinnedSumRow(agGrid: AgGridAngular, pinnedColKeys, callBack) {
    let windowType;
    let entityId;
    let searchEntityType;
    let searchKeyColumn;
    let validationCode;
    let query;
    if (this.data.validationCode) {
      validationCode = LogicEvaluator.replaceVariables(
        this.data.validationCode,
        this.connectorService.getIupicsUserContext(),
        this.getCurrentContext()
      );
    }
    if (this.isSearch) {
      query = this.query;
      windowType = CompiereDataGridType.SEARCH_WINDOW;
      if (this.data.validationCode) {
        validationCode = LogicEvaluator.replaceVariables(
          this.data.validationCode,
          this.connectorService.getIupicsUserContext(),
          this.getCurrentContext()
        );
      }
      switch (this.windowType) {
        case IupicsMenuType.WINDOW:
          windowType = CompiereDataGridType.SEARCH_WINDOW;
          entityId = this.data.details.entityID;
          searchEntityType = this.data.details.entityType;
          searchKeyColumn = this.data.details.keyColumn;
          break;
        case IupicsMenuType.PROCESS:
          windowType = CompiereDataGridType.SEARCH_WINDOW;
          entityId = this.data.details.entityID;
          searchEntityType = this.data.details.entityType;
          searchKeyColumn = this.data.details.keyColumn;
          break;
        case IupicsMenuType.FORM:
          windowType = CompiereDataGridType.FORM;
          entityId = this.data['AD_FormDetail_ID'];
          break;
        default:
          windowType = CompiereDataGridType.SEARCH_WINDOW;
          entityId = this.data.details.entityID;
          searchEntityType = this.data.details.entityType;
          searchKeyColumn = this.data.details.keyColumn;
          break;
      }
    } else {
      windowType = CompiereDataGridType.WINDOW;
      entityId = this.tabId;
    }
    const windowId =
      this.windowType === IupicsMenuType.WINDOW
        ? this.data.fieldId
        : this.data.columnId;
    const dataStoreRequest: DataStoreRequest = {
      windowId: this.container ? this.data.AD_window_ID : windowId,
      compiereRequest: {
        windowType: windowType,
        entityId: entityId,
        startRow: 0,
        endRow: 0,
      },
    };
    if (this.parent_constraint) {
      // On ajoute la contrainte du parent si il y en a une
      dataStoreRequest.parent_constraint = this.parent_constraint;
    }
    if (this.filterModel && Object.keys(this.filterModel).length > 0) {
      // On ajoute le filtre si il y en a un
      dataStoreRequest.compiereRequest.filterModel = this.filterModel;
    }
    // if (this.groupColumn) {
    //   // On ajoute le filtre si il y en a un
    //   dataStoreRequest.compiereRequest.rowGroupCols = this.groupColumn;
    // }
    if (this.validation) {
      // On ajoute la validation si il y en déjà a une
      dataStoreRequest.compiereRequest.validation = this.validation;
    }
    if (this.validation_sql) {
      // On ajoute la validation SQL si il y en a une
      dataStoreRequest.compiereRequest.validation = this.validation_sql;
    }
    if (validationCode && this.windowType !== IupicsMenuType.FORM) {
      dataStoreRequest.compiereRequest.validation = dataStoreRequest
        .compiereRequest.validation
        ? dataStoreRequest.compiereRequest.validation + ' and ' + validationCode
        : validationCode;
    }
    dataStoreRequest.compiereRequest.searchEntityType = searchEntityType;
    dataStoreRequest.compiereRequest.searchKeyColumn = searchKeyColumn;
    if (this.DOMParentComponent) {
      dataStoreRequest.compiereRequest.parent_formDetail_id = (<
        GridViewUiComponent
      >this.DOMParentComponent).parentFormID;
    }
    // query des forms
    if (query) {
      this.createFilterModelFromForm(query, dataStoreRequest);
    }
    const bottomRow = {};
    const result = [];
    if (pinnedColKeys.length > 0) {
      const columnNames = [];
      dataStoreRequest.compiereRequest.valueCols = [];
      pinnedColKeys.forEach((key) => {
        const colDef = agGrid.columnApi.getColumn(key).getColDef();
        const columnName = colDef.field;
        const displayName = colDef.headerName;
        columnNames.push(columnName);
        dataStoreRequest.compiereRequest.valueCols.push({
          id: columnName,
          aggFunc: 'sum',
          displayName: displayName,
          field: columnName,
        });
      });
      this.dataStore
        .getDataGrid(dataStoreRequest)
        .subscribe((response: CompiereDataGridResponseJSON) => {
          if (response.data && response.data[0]) {
            columnNames.forEach((columnName) => {
              if (response.data[0][columnName] !== null) {
                const value: number = response.data[0][columnName];
                try {
                  bottomRow[columnName] = value.toFixed(2);
                } catch (e) {
                  bottomRow[columnName] = 0;
                }
              } else {
                bottomRow[columnName] = 0;
              }
            });
            result.push(bottomRow);
          }
          callBack(result);
        });
    }
  }
  /**
   * permet de setté une row correspondant à un recordId
   * @param recordId identifiant recherché
   * renvoie si l'élément a été trouvé
   */
  setSelectedRow(recordId: string, castPageChange: boolean = true): boolean {
    let found = false;
    // aller à la page sur laquelle on était
    if (
      castPageChange &&
      this.currentSelectPageIndex > this.agGrid.api.paginationGetCurrentPage()
    ) {
      this.agGrid.api.paginationGoToPage(this.currentSelectPageIndex);
      if (this.DOMParentComponent) {
        (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId =
          recordId;
      }
      this.currentSelectIndex = null;
      this.agGrid.api.deselectAll();
      this.shouldChangePage = false;
    }
    this.agGrid.api.forEachNode((node) => {
      if (node.data && node.data['Data_UUID'] == recordId) {
        this.currentSelectIndex = node.rowIndex;
        const nbRowPerPage = this.agGrid.api.paginationGetPageSize();
        const currentSelectPageIndex = Math.trunc(+node.id / nbRowPerPage);
        if (castPageChange) {
          this.agGrid.api.paginationGoToPage(currentSelectPageIndex);
          this.shouldChangePage = false;
        }
        if (!node['selected']) {
          node.setSelected(true);
        }
        found = true;
        return;
      }
    });
    // if (!found) {
    // this.agGrid.api.paginationGoToPage(this.agGrid.api.paginationGetTotalPages() + 1);
    // }
    return found;
  }
  simplifyData(datas: any[], checkTempRow = false): any[] {
    if (checkTempRow && this.tempDataItems.length > 0) {
      this.tempDataItems.forEach((item) => {
        datas.splice(0, 0, item);
      });
    }
    const dataRefactored = Object.assign([], datas);
    dataRefactored.forEach((data) =>
      Object.keys(data).forEach((key) => {
        if (data[key] && data[key].id !== null && data[key].id !== undefined) {
          this.gatheredComplexDatas[key + '$' + data[key].id] =
            data[key].displayValue;
          data[key] = data[key].id;
        }
      })
    );
    return dataRefactored;
  }

  getDisplayValue(columnName: string, id: number): any {
    return this.gatheredComplexDatas[columnName + '$' + id];
  }

  gridToolsInternationalization(): any {
    this.localeText = {
      // for filter panel
      page: this.translate.instant('gridTools.page'),
      more: this.translate.instant('gridTools.more'),
      to: this.translate.instant('gridTools.to'),
      of: this.translate.instant('gridTools.of'),
      next: this.translate.instant('gridTools.next'),
      last: this.translate.instant('gridTools.last'),
      first: this.translate.instant('gridTools.first'),
      previous: this.translate.instant('gridTools.previous'),
      loadingOoo: this.translate.instant('gridTools.loadingOoo'),

      // for set filter
      selectAll: this.translate.instant('gridTools.selectAll'),
      searchOoo: this.translate.instant('gridTools.searchOoo'),
      blanks: this.translate.instant('gridTools.blanks'),

      // for number filter and text filter
      filterOoo: this.translate.instant('gridTools.filterOoo'),
      applyFilter: this.translate.instant('gridTools.applyFilter'),

      // for number filter
      equals: this.translate.instant('gridTools.equals'),
      lessThan: this.translate.instant('gridTools.lessThan'),
      greaterThan: this.translate.instant('gridTools.greaterThan'),

      // for text filter
      contains: this.translate.instant('gridTools.contains'),
      notContains: this.translate.instant('gridTools.notContains'),
      clearFilter: this.translate.instant('gridTools.clearFilter'),
      startsWith: this.translate.instant('gridTools.startsWith'),
      endsWith: this.translate.instant('gridTools.endsWith'),

      // the header of the default group column
      group: this.translate.instant('gridTools.group'),

      // tool panel
      columns: this.translate.instant('gridTools.columns'),
      rowGroupColumns: this.translate.instant('gridTools.rowGroupColumns'),
      rowGroupColumnsEmptyMessage: this.translate.instant(
        'gridTools.rowGroupColumnsEmptyMessage'
      ),
      valueColumns: this.translate.instant('gridTools.valueColumns'),
      pivotMode: this.translate.instant('gridTools.pivotMode'),
      groups: this.translate.instant('gridTools.groups'),
      values: this.translate.instant('gridTools.values'),
      pivots: this.translate.instant('gridTools.pivots'),
      valueColumnsEmptyMessage: this.translate.instant(
        'gridTools.valueColumnsEmptyMessage'
      ),
      pivotColumnsEmptyMessage: this.translate.instant(
        'gridTools.pivotColumnsEmptyMessage'
      ),
      toolPanelButton: this.translate.instant('gridTools.toolPanelButton'),

      // other
      noRowsToShow: this.translate.instant('gridTools.noRowsToShow'),

      // enterprise menu
      pinColumn: this.translate.instant('gridTools.pinColumn'),
      valueAggregation: this.translate.instant('gridTools.valueAggregation'),
      autosizeThiscolumn: this.translate.instant(
        'gridTools.autosizeThiscolumn'
      ),
      autosizeAllColumns: this.translate.instant(
        'gridTools.autosizeAllColumns'
      ),
      groupBy: this.translate.instant('gridTools.groupBy'),
      ungroupBy: this.translate.instant('gridTools.ungroupBy'),
      resetColumns: this.translate.instant('gridTools.resetColumns'),
      expandAll: this.translate.instant('gridTools.expandAll'),
      collapseAll: this.translate.instant('gridTools.collapseAll'),
      toolPanel: this.translate.instant('gridTools.toolPanel'),
      export: this.translate.instant('gridTools.export'),
      csvExport: this.translate.instant('gridTools.csvExport'),
      excelExport: this.translate.instant('gridTools.excelExport'),

      // enterprise menu pinning
      pinLeft: this.translate.instant('gridTools.pinLeft'),
      pinRight: this.translate.instant('gridTools.pinRight'),
      noPin: this.translate.instant('gridTools.noPin'),

      // enterprise menu aggregation and status panel
      sum: this.translate.instant('gridTools.sum'),
      min: this.translate.instant('gridTools.min'),
      max: this.translate.instant('gridTools.max'),
      none: this.translate.instant('gridTools.none'),
      count: this.translate.instant('gridTools.count'),
      average: this.translate.instant('gridTools.average'),

      // standard menu
      copy: this.translate.instant('gridTools.copy'),
      copyWithHeaders: this.translate.instant('gridTools.copyWithHeaders'),
      ctrlC: this.translate.instant('gridTools.ctrlC'),
      paste: this.translate.instant('gridTools.paste'),
      ctrlV: this.translate.instant('gridTools.ctrlV'),
    };
  }
  _getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    const result = [
      'copy',
      'separator',
      {
        name: 'Exporter',
        subMenu: [
          {
            name: this.translate.instant('gridTools.context.csvExport'),
            action: () => {
              if (this.currentStateRequest) {
                this.createExport('csv');
              } else {
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translate.instant('exportData.messageTitle'),
                    this.translate.instant('exportData.exportDataFail'),
                    'error'
                  )
                );
              }
            },
            icon: null,
          },
          {
            name: this.translate.instant('gridTools.context.excelExport'),
            action: () => {
              if (this.currentStateRequest) {
                this.createExport('xlsx');
              } else {
                this.messageManager.newMessage(
                  new IupicsMessage(
                    this.translate.instant('exportData.messageTitle'),
                    this.translate.instant('exportData.exportDataFail'),
                    'error'
                  )
                );
              }
            },
            icon: null,
          },
        ],
      },
      ...this.getAdditionnalMenuItems(params),
    ];
    return result;
  }

  public getAdditionnalMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    return [];
  }

  createExport(type: 'csv' | 'xlsx') {
    const request = cloneDeep(this.currentStateRequest);
    request.startRow = 0;
    request.endRow = this.config.getConstant('MAX_RECORD_EXPORT');
    request.headerCols = this.agGrid.columnApi
      .getAllColumns()
      .filter((col) => col.isVisible())
      .sort((colA, colB) => colA.getLeft() - colB.getLeft())
      .map((col) => {
        return <CompiereDataGridGroupModel>{
          id: col.getColId(),
          field: col.getColId(),
          displayName: col.getColDef().headerName,
        };
      });
    if (this.data.TableName && this.agGrid && this.agGrid.api) {
      const selectedRows = this.agGrid.api.getSelectedRows();
      if (selectedRows.length > 0) {
        const recordIds = selectedRows
          .map((a) => {
            return a['Data_UUID'];
          })
          .filter((record) => record !== null);
        const columnnames = [];
        const values = [];
        recordIds.forEach((rec) => {
          const tab = rec.split(',');
          for (let i = 0; i < tab.length; i++) {
            if (i % 2 == 0) {
              if (
                columnnames.find((e) => {
                  if (e != null && e != undefined) {
                    return tab[i] == e;
                  } else {
                    return false;
                  }
                }) == undefined
              ) {
                columnnames.push(tab[i]);
              }
            } else {
              if (Math.trunc(i / 2) + 1 > values.length) {
                values.push([tab[i]]);
              } else {
                values[Math.trunc(i / 2)].push(tab[i]);
              }
            }
          }
        });
        request.filterModel = {};
        for (let i = 0; i < columnnames.length; i++) {
          request.filterModel[columnnames[i]] = {
            filterType: CompiereDataGridFilterType.NUMBER,
            values: [values[i]],
            operators: [OperatorFilterType.EQUALS],
          };
        }
      }
    }

    // console.log(request);
    // return;
    request.windowCtx = this.getCurrentContext();
    this.exportDataService.exportDataGrid(request, type).subscribe(
      (message: string) =>
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translate.instant('exportData.messageTitle'),
            this.translate.instant('exportData.exportDataStarted'),
            'success',
            message
          )
        ),
      (message: string) =>
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translate.instant('exportData.messageTitle'),
            this.translate.instant('exportData.exportDataFail'),
            'error',
            message
          )
        )
    );
  }
  onRowGroupOpened(params) {
    if (this.windowType === IupicsMenuType.FORM && params.node.expanded) {
      // node was expanded
      this.tableHeight = this.getConstant('DEFAULT_MAX_TABLE_HEIGHT');
    }
  }

  onCellEditingStopped(event: any) {
    this.gridCellEditingStopped.emit(event);
  }
  /**
   * Permet de créer la validation du datagridRequest sur base de la query venant de la form
   */
  createValidationFromForm(validations, dataStoreRequest) {
    if (validations) {
      let validation = '';
      const validationsReformat = Object.assign({}, validations);
      Object.keys(validationsReformat).forEach((key) => {
        if (validationsReformat[key] instanceof Object) {
          validationsReformat[key] = validationsReformat[key].id;
        }
        validation +=
          (validation.length > 0 ? ',' : '') +
          key +
          ',' +
          validationsReformat[key];
      });
      if (this.validation !== validation) {
        this.validation = validation;
        dataStoreRequest.compiereRequest.validation = validation;
        return true;
      } else {
        return false;
      }
    }
  }
  /**
   * Creer un filterModel à partir de la query envoyée par le form
   * @param query : query contenant les filtres, les validations et un paramètre forced qui indique si il faut reformater le filtermodel
   * @param dataStoreRequest : request à alimenter
   * @param forced : savoir si il faut forcer le reformatage des values du filtermodel: value => id, displayvalue
   */
  createFilterModelFromForm(query, dataStoreRequest, forced = false) {
    let filterModel = Object.assign(
      {},
      dataStoreRequest.compiereRequest.filterModel
    );
    let filterModelChanged = forced;
    const filters = query.filters;
    const validations = query.validations;
    const fieldsFromForm = query.fields ? query.fields : [];
    if (validations) {
      filterModelChanged = this.createValidationFromForm(
        query.validations,
        dataStoreRequest
      );
    }
    const filtersReformat = Object.assign({}, filters);
    const gridView = <GridViewUiComponent>this.DOMParentComponent;
    const datastore =
      gridView && gridView.fromForm && gridView.container
        ? gridView.container.dataStore
        : null;
    if (filters && Object.keys(filters).length > 0) {
      /*on supprime les filtres qui ont été vidé dans les filtres de la form */
      Object.keys(fieldsFromForm).forEach((key) => {
        if (
          fieldsFromForm[key] &&
          fieldsFromForm[key].data.isFilterSearch === true
        ) {
          if (
            !datastore ||
            datastore.data[key] === undefined ||
            datastore.data[key] === null
          ) {
            if (filterModel[key]) {
              filterModelChanged = true;
            }
            delete filterModel[key];
          }
          if (
            datastore &&
            datastore.data[key] !== undefined &&
            datastore.data[key] !== null
          ) {
            if (
              !datastore.data[
                key.endsWith('_To') ? key.substring(0, key.length - 3) : key
              ]
            ) {
              filtersReformat[key] = datastore.data[key];
              if (!filterModel[key]) {
                filterModelChanged = true;
              }
            }
          }
        }
      });
      Object.keys(filtersReformat).forEach((key) => {
        // On vérifie le type de champ pour formater la value à indiquer dans le filterModel
        const filterType = fieldsFromForm[key]
          ? UICreatorUtils.getFilterTypeFromReference(
              fieldsFromForm[key]['referenceId']
            )
          : typeof filtersReformat[key] === 'number'
          ? key.includes('_ID')
            ? CompiereDataGridFilterType.SET
            : CompiereDataGridFilterType.NUMBER
          : CompiereDataGridFilterType.TEXT;
        if (!(filtersReformat[key] instanceof Array)) {
          filtersReformat[key] = [filtersReformat[key]];
        }
        if (!filterModel) {
          filterModel = {};
        }
        if (filterModel[key]) {
          /*cas des Ranges */
          if (fieldsFromForm[key] && fieldsFromForm[key].data.IsRange) {
            const filterFrom = filtersReformat[key][0];
            const filterTo = filtersReformat[key][1]
              ? filtersReformat[key][1]
              : datastore && datastore.data
              ? datastore.data[key + '_To']
              : null;
            let newOperator = OperatorFilterType.BETWEEN;
            let newValues = [filterFrom, filterTo];

            if (!filterFrom && filterTo) {
              newOperator = OperatorFilterType.LESS_EQUALS;
              newValues = [filterTo];
            } else if (filterFrom && !filterTo) {
              newOperator = OperatorFilterType.BIGGER_EQUALS;
              newValues = [filterFrom];
            }

            if (
              filterModel[key].operators.findIndex(
                (operator) => operator === newOperator
              ) !== -1
            ) {
              // Même opérateur ==> on remplace la valeur existante par la nouvelle si elle est différente
              for (let i = 0; i < newValues.length; i++) {
                let filtersReformatValue = newValues[i];
                if (fieldsFromForm[key].referenceId === 15) {
                  filtersReformatValue =
                    moment(filtersReformatValue)
                      .format('YYYY-MM-DDTHH:mm:ss.SSS')
                      .substring(0, 26) +
                    '' +
                    moment(filtersReformatValue)
                      .format('YYYY-MM-DDTHH:mm:ss.SSS')
                      .substring(
                        27,
                        moment(filtersReformatValue).format(
                          'YYYY-MM-DDTHH:mm:ss.SSS'
                        ).length
                      );
                }
                const idFromFormTmp =
                  filtersReformatValue instanceof Object
                    ? filtersReformatValue.id
                    : filtersReformatValue;
                if (filterModel[key].values[i] != idFromFormTmp) {
                  // Dans le cas d'un autocomplete, la ou les valeurs doivent être dans un array
                  filterModel[key].values[i] =
                    filterType === CompiereDataGridFilterType.SET
                      ? filtersReformatValue
                      : idFromFormTmp;
                  filterModelChanged = true;
                }
              }
            } else {
              // Changement d'opérateur
              filterModel[key].values = newValues;
              filterModel[key].operators =
                newOperator === OperatorFilterType.BETWEEN
                  ? [newOperator, newOperator]
                  : [newOperator];
              filterModelChanged = true;
            }

            // const foundOperatorIndex = filterModel[key].operators.findIndex(
            //   (operator) => operator === OperatorFilterType.BETWEEN
            // );
            /*on ajoute un operateur BETWEEN car inexistant  */
            // if (foundOperatorIndex === -1) {
            // filterModelChanged = true;
            // filterModel[key].operators.push(OperatorFilterType.BETWEEN);
            // for (let i = 0; i < 2; i++) {
            //   const filtersReformatValue = filtersReformat[key][i];
            //   filterModel[key].operators.push(OperatorFilterType.BETWEEN);
            //   filterModel[key].values.push(filtersReformatValue);
            // }
            // } else {
            /*on remplace la valeur existante par la nouvelle si elle est différente  */
            // for (let i = 0; i < filtersReformat[key].length; i++) {
            //   let filtersReformatValue = filtersReformat[key][i];
            //   if (fieldsFromForm[key].referenceId === 15) {
            //     filtersReformatValue =
            //       moment(filtersReformatValue).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
            //       '' +
            //       moment(filtersReformatValue)
            //         .format('YYYY-MM-DDTHH:mm:ss.SSS')
            //         .substring(27, moment(filtersReformatValue).format('YYYY-MM-DDTHH:mm:ss.SSS').length);
            //   }
            //   const idFromFormTmp = filtersReformatValue instanceof Object ? filtersReformatValue.id : filtersReformatValue;
            //   if (filterModel[key].values[i] != idFromFormTmp) {
            //     // Dans le cas d'un autocomplete, la ou les valeurs doivent être dans un array
            //     filterModel[key].values[i] = filterType === CompiereDataGridFilterType.SET ? filtersReformatValue : idFromFormTmp;
            //     filterModelChanged = true;
            //   }
            // }
            // }
          } else {
            const foundOperatorIndex = filterModel[key].operators.findIndex(
              (operator) =>
                filterType === CompiereDataGridFilterType.TEXT
                  ? operator === OperatorFilterType.CONTAINS
                  : operator === OperatorFilterType.EQUALS
            );
            /*on ajoute un operateur equals car inexistant */
            if (foundOperatorIndex === -1) {
              filterModelChanged = true;
              filterModel[key].operators.push(OperatorFilterType.EQUALS);
              filterModel[key].values.push(filtersReformat[key]);
            } else {
              /*on remplace la valeur existante par la nouvelle si elle est différente */
              filtersReformat[key].forEach((id) => {
                const idFromFormTmp = id instanceof Object ? id.id : id;
                if (
                  filterModel[key].values[foundOperatorIndex] !=
                    idFromFormTmp &&
                  (!(
                    filterModel[key].values[foundOperatorIndex] instanceof Array
                  ) ||
                    filterModel[key].values[foundOperatorIndex].find(
                      (value) => {
                        const idTmp =
                          value instanceof Object ? value.id : value;
                        if (idTmp == idFromFormTmp) {
                          return true;
                        }
                      }
                    ) === undefined)
                ) {
                  // Dans le cas d'un autocomplete, la ou les valeurs doivent être dans un array
                  filterModel[key].values[foundOperatorIndex] =
                    filterType === CompiereDataGridFilterType.SET
                      ? filtersReformat[key]
                      : filtersReformat[key][0];
                  filterModelChanged = true;
                }
              });
            }
          }
        } else {
          /*on ajoute le filtre qui n'existe pas encore */
          filterModelChanged = true;
          if (fieldsFromForm[key] && fieldsFromForm[key].data.IsRange) {
            const filterFrom = filtersReformat[key][0];
            const filterTo = filtersReformat[key][1];

            if (!filterFrom && filterTo) {
              filterModel[key] = {
                filterType: filterType,
                values: [filterTo],
                operators: [OperatorFilterType.LESS_EQUALS],
              };
            } else if (filterFrom && !filterTo) {
              filterModel[key] = {
                filterType: filterType,
                values: [filterFrom],
                operators: [OperatorFilterType.BIGGER_EQUALS],
              };
            } else if (filterFrom && filterTo) {
              // Dans le cas d'un range
              filterModel[key] = {
                filterType: filterType,
                values: filtersReformat[key],
                operators: [
                  OperatorFilterType.BETWEEN,
                  OperatorFilterType.BETWEEN,
                ],
              };
            }
          } else {
            filterModel[key] = {
              filterType: filterType,
              values: [
                filterType === CompiereDataGridFilterType.SET
                  ? filtersReformat[key]
                  : filtersReformat[key][0],
              ],
              operators: [
                filterType === CompiereDataGridFilterType.TEXT
                  ? OperatorFilterType.CONTAINS
                  : OperatorFilterType.EQUALS,
              ],
            };
          }
        }
      });
    } else {
      /*On supprime les filtres de la form vides */
      Object.keys(fieldsFromForm).forEach((key) => {
        if (
          fieldsFromForm[key] &&
          fieldsFromForm[key].data.isFilterSearch === true
        ) {
          if (
            !datastore ||
            datastore.data[key] === undefined ||
            datastore.data[key] === null
          ) {
            if (filterModel[key]) {
              filterModelChanged = true;
            }
            delete filterModel[key];
          }
          if (
            datastore &&
            datastore.data[key] !== undefined &&
            datastore.data[key] !== null
          ) {
            filtersReformat[key] = datastore.data[key];
            if (!filterModel[key]) {
              filterModelChanged = true;
            }
          }
        }
      });
    }
    const ignoredFilter =
      gridView && gridView.fromForm && gridView.container
        ? gridView.container.gridExcludedFilterValidation.get(
            gridView.data.columnName
          )
        : null;
    // suppression des filtres ignorés par la spécific window
    if (ignoredFilter) {
      ignoredFilter.forEach((fieldColumnName) => {
        if (filterModel[fieldColumnName] || filtersReformat[fieldColumnName]) {
          delete filterModel[fieldColumnName];
          delete filtersReformat[fieldColumnName];
          filterModelChanged = true;
        }
      });
    }
    if (filterModelChanged) {
      /* en cas de modification du filterModel,il faut remettre le filtermodel sous forme id displayvalue pour le passer à l'universalfilter */
      Object.keys(filtersReformat).forEach((key) => {
        // On vérifie le type de champ pour formater la value à indiquer dans le filterModel
        const filterType = fieldsFromForm[key]
          ? UICreatorUtils.getFilterTypeFromReference(
              fieldsFromForm[key]['referenceId']
            )
          : typeof filtersReformat[key] === 'number'
          ? key.includes('_ID')
            ? CompiereDataGridFilterType.SET
            : CompiereDataGridFilterType.NUMBER
          : CompiereDataGridFilterType.TEXT;
        if (filterModel[key]) {
          if (fieldsFromForm[key] && fieldsFromForm[key].data.IsRange) {
            // Dans le cas d'un range
            filterModel[key].values = filtersReformat[key].filter((el) => el);
          } else {
            const foundOperatorIndex = filterModel[key].operators.findIndex(
              (operator) =>
                filterType === CompiereDataGridFilterType.TEXT
                  ? operator === OperatorFilterType.CONTAINS
                  : operator === OperatorFilterType.EQUALS
            );
            filterModel[key].values[foundOperatorIndex] =
              filterType === CompiereDataGridFilterType.SET
                ? filtersReformat[key]
                : filtersReformat[key][0];
          }
        }
      });
      dataStoreRequest.compiereRequest.filterModel = filterModel;
    }
    return forced ? true : filterModelChanged;
  }
  /* Permet de savoir si c'est un évenement sur l'aggrid qui a déclenché la mise à jour de la request */
  getAggridSourceOfRequest(aggridRequest: IServerSideGetRowsRequest) {
    let propName = null;
    if (this.fromUFData.active) {
      return null;
    }
    if (aggridRequest.sortModel) {
      if (
        this.sortModel &&
        aggridRequest.sortModel.length >= this.sortModel.length
      ) {
        aggridRequest.sortModel.forEach((sortGrid) => {
          if (
            !this.sortModel.find(
              (sort) =>
                sort.colId === sortGrid.colId && sort.sort === sortGrid.sort
            )
          ) {
            propName = 'sortModel';
            return;
          }
        });
      } else {
        return 'sortModel';
      }
    }
    if (aggridRequest.rowGroupCols) {
      if (
        this.groupColumn &&
        aggridRequest.rowGroupCols.length >= this.groupColumn.length
      ) {
        aggridRequest.rowGroupCols.forEach((groupGrid) => {
          if (!this.groupColumn.find((group) => group.id === groupGrid.id)) {
            propName = 'groupColumn';
            return;
          }
        });
      } else {
        return 'groupColumn';
      }
    }
    if (aggridRequest.valueCols) {
      if (
        this.valueCols &&
        aggridRequest.valueCols.length >= this.valueCols.length
      ) {
        aggridRequest.valueCols.forEach((valueColGrid) => {
          if (
            !this.valueCols.find(
              (valueCol) =>
                valueCol.id === valueColGrid.id &&
                valueCol.aggFunc === valueColGrid.aggFunc
            )
          ) {
            propName = 'valueCols';
            return;
          }
        });
      } else {
        return 'valueCols';
      }
    }
    if (aggridRequest.pivotCols) {
      if (
        this.pivotCols &&
        aggridRequest.pivotCols.length >= this.pivotCols.length
      ) {
        aggridRequest.pivotCols.forEach((pivotColGrid) => {
          if (
            !this.pivotCols.find((pivotCol) => pivotCol.id === pivotColGrid.id)
          ) {
            propName = 'pivotCols';
            return;
          }
        });
      } else {
        return 'pivotCols';
      }
    }
    if (aggridRequest.pivotMode !== this.pivotMode) {
      return 'pivotMode';
    }
    return propName;
  }

  toolPanelVisibleChanged() {
    if (
      this.agGrid.api.isToolPanelShowing() &&
      this.tableHeight !== undefined &&
      this.tableHeight !== '100%' &&
      this.tableHeight !== this.getConstant('DEFAULT_MAX_TABLE_HEIGHT')
    ) {
      this.tableHeightBackup = this.tableHeight;
      this.tableHeight = this.getConstant('DEFAULT_MAX_TABLE_HEIGHT');
    } else if (
      !this.agGrid.api.isToolPanelShowing() &&
      this.tableHeightBackup !== undefined
    ) {
      this.tableHeight = this.tableHeightBackup;
      this.tableHeightBackup = undefined;
    }
  }
  getRows(
    dataStoreRequest: DataStoreRequest,
    params: IServerSideGetRowsParams,
    isNewRecord: boolean,
    indexEditViewToEdit: number,
    recordChanged: boolean,
    callBack = null
  ): void {
    if (!this.agGrid) {
      return;
    }
    this.isGetRowsInProgress = true;
    const forced = this.forceCallWs;
    this.forceCallWs = true;
    let key = JSON.stringify(
      this.dataStore.generateDataStoreKey(
        dataStoreRequest.windowId,
        dataStoreRequest.compiereRequest.entityId,
        dataStoreRequest.record_id,
        dataStoreRequest.parent_constraint
      )
    );
    this.fromUFData.forceSort = false;
    this.fromUFData.forceFilter = false;

    if (dataStoreRequest.compiereRequest.groupKeys?.length > 0)
      key += dataStoreRequest.compiereRequest.groupKeys;
    if (this.subscriptionsGetRows.has(key))
      this.subscriptionsGetRows.get(key)?.unsubscribe();

    if (dataStoreRequest.compiereRequest.groupKeys?.length == 0)
      this.currentStateRequest = dataStoreRequest.compiereRequest;

    this.subscriptionsGetRows.set(
      key,
      this.dataStore.getDataGrid(dataStoreRequest, forced).subscribe(
        (result) => {
          if (this.agGrid.gridOptions.api) {
            this.isGetRowsInProgress = false;
            // this.lastGetRowsParams = { dataStoreRequest: dataStoreRequest, params: params };
            if (result.compiereRequest.groupKeys?.length === 0)
              this.currentStateRequest = result.compiereRequest;
            if (result.compiereRequest.rowGroupCols.length > 0) {
              if (
                result.compiereRequest.groupKeys.length === 0 ||
                result.compiereRequest.groupKeys.length !==
                  result.compiereRequest.rowGroupCols.length
              ) {
                for (const data of result.data) {
                  for (const g of result.compiereRequest.rowGroupCols) {
                    if (data[g.id] === null || data[g.id] === undefined) {
                      data[g.id] = '';
                    }
                  }
                }
              }
            }
            if (result) {
              // this.subscriptions.push(
              // this.fillPrecisionCache(result.data).subscribe(() => {
              if (this.isSearch) {
                const data = result.data ? result.data : [];
                if (this.agGrid) {
                  if (!this.overridedTableHeight) {
                    if (params.request.groupKeys.length <= 0) {
                      if (!data || data.length < 1) {
                        if (
                          this.pinnedColKeys &&
                          this.pinnedColKeys.length > 0
                        ) {
                          this.tableHeight =
                            GridTabInfinityScrollConstant.DEFAULT_EMPTY_HEIGHT_WITH_PINNED_COLS;
                        } else {
                          this.tableHeight =
                            GridTabInfinityScrollConstant.DEFAULT_EMPTY_HEIGHT;
                          // this.tableHeight = '80px';
                        }
                      } else if (
                        dataStoreRequest.compiereRequest.startRow === 0 &&
                        data.length <
                          this.getConstant('DEFAULT_MAXIMUM_NUMBER_LINES')
                      ) {
                        this.calculeTableHeight =
                          (data.length + 1) *
                            GridTabInfinityScrollConstant.ROW_PAGINATOR_HEIGHT +
                          100;
                        if (
                          this.pinnedColKeys &&
                          this.pinnedColKeys.length > 0
                        ) {
                          this.calculeTableHeight += 40;
                        }
                        this.tableHeight = this.calculeTableHeight + 'px';
                      } else {
                        this.tableHeight = this.getConstant(
                          'DEFAULT_MAX_TABLE_HEIGHT'
                        );
                      }
                    } else {
                      if (
                        this.tableHeight !==
                        this.getConstant('DEFAULT_MAX_TABLE_HEIGHT')
                      ) {
                        if (
                          dataStoreRequest.compiereRequest.startRow === 0 &&
                          data.length <
                            this.getConstant('DEFAULT_MAXIMUM_NUMBER_LINES')
                        ) {
                          if (
                            this.calculeTableHeight +
                              (data.length *
                                GridTabInfinityScrollConstant.ROW_PAGINATOR_HEIGHT +
                                100) <
                            340
                          ) {
                            this.calculeTableHeight +=
                              data.length *
                                GridTabInfinityScrollConstant.ROW_PAGINATOR_HEIGHT +
                              100;
                            this.tableHeight += this.calculeTableHeight + 'px';
                          } else {
                            this.tableHeight = this.getConstant(
                              'DEFAULT_MAX_TABLE_HEIGHT'
                            );
                          }
                        }
                      }
                    }
                  }
                  this.agGrid.api.hideOverlay();
                  if (
                    result.lastRow > -1 &&
                    params.request.startRow === 0 &&
                    this.tempDataItems.length > 0
                  ) {
                    result.lastRow = result.lastRow + this.tempDataItems.length;
                  }
                  // params.successCallback(this.simplifyData(result.data, params.request.startRow === 0), result.lastRow);
                  params.success({
                    rowData: this.simplifyData(
                      result.data,
                      params.request.startRow === 0
                    ),
                    rowCount: result.lastRow,
                  });
                  this.updateSecondaryColumns(
                    dataStoreRequest.compiereRequest,
                    result
                  );
                  if (
                    data &&
                    data.length > 0 &&
                    this.data.shouldSelectFirst &&
                    this.agGrid.api.getDisplayedRowAtIndex(0)
                  ) {
                    this.agGrid.api.getDisplayedRowAtIndex(0).setSelected(true);
                  }
                  this.columnResize();
                }
                if (
                  dataStoreRequest.compiereRequest.startRow +
                    result.data.length >
                  0
                ) {
                  if (recordChanged) {
                    this.selectFirstLine(result.data[0]);
                    recordChanged = false;
                  } else {
                    this.selectLastLineSelected(
                      this.shouldChangePage || !recordChanged
                    );
                  }
                }
                // creation d'une ligne de total pour les colonnes spécifiées
                if (this.pinnedColKeys) {
                  this.createPinnedSumRow(
                    this.agGrid,
                    this.pinnedColKeys,
                    (response) => {
                      this.pinnedBottomRow = response;
                      this.agGrid.api.setPinnedBottomRowData(
                        this.pinnedBottomRow
                      );
                    }
                  );
                }
              } else {
                // params.successCallback(this.simplifyData(result.data), result.lastRow);
                params.success({
                  rowData: this.simplifyData(result.data),
                  rowCount: result.lastRow,
                });
                this.updateSecondaryColumns(
                  dataStoreRequest.compiereRequest,
                  result
                );
                if (!this.isTabTopLevel && !this.overridedTableHeight) {
                  // On gère dynamiquement la hauteur quand grid dans une edit view
                  if (
                    !result.data ||
                    dataStoreRequest.compiereRequest.startRow +
                      result.data.length <
                      1
                  ) {
                    this.tableHeight =
                      GridTabInfinityScrollConstant.DEFAULT_EMPTY_HEIGHT;
                  } else if (
                    dataStoreRequest.compiereRequest.startRow +
                      result.data.length <
                    this.getConstant('DEFAULT_MAXIMUM_NUMBER_LINES')
                  ) {
                    this.calculeTableHeight =
                      (result.data.length + 1) *
                        GridTabInfinityScrollConstant.ROW_PAGINATOR_HEIGHT +
                      100;
                    this.tableHeight = this.calculeTableHeight + 'px';
                  } else {
                    this.tableHeight = this.getConstant(
                      'DEFAULT_MAX_TABLE_HEIGHT'
                    );
                  }
                  (<GridViewUiComponent>(
                    this.DOMParentComponent
                  )).openRemainingEditView();
                }
                if (!isNewRecord && indexEditViewToEdit) {
                  if (
                    dataStoreRequest.compiereRequest.startRow +
                      result.data.length >
                    0
                  ) {
                    if (recordChanged) {
                      this.selectFirstLine(result.data[0]);
                      recordChanged = false;
                    } else {
                      this.selectLastLineSelected(
                        this.shouldChangePage || !recordChanged
                      );
                    }
                  } else if (this.currentSelectIndex >= 0) {
                    this.closeLinkedEditview();
                    this.currentSelectIndex = undefined;
                  }
                } else {
                  this.selectLastLineSelected(this.shouldChangePage);
                }
                this.columnResize();
              }
              // })
              // );
            } else {
              // force to hide overlay when no data
              // params.successCallback([], 0);
              params.success({ rowData: [], rowCount: 0 });
            }
            this.gridRefreshed.emit(dataStoreRequest);
            if (callBack) {
              callBack();
            }
          }
        },
        () => {
          this.isGetRowsInProgress = false;
          if (callBack) {
            callBack();
          }
        }
      )
    );
  }

  /**
   * Ferme l'editview liée
   */
  private closeLinkedEditview() {
    const linkedEditView = this.container.DOMChildrenComponent.find(
      (child) => child.tabId === this.tabId
    );
    if (!isNil(linkedEditView)) {
      linkedEditView.removeComponent();
    }
  }

  public updateColumnState() {
    if (!this.agGrid) {
      return;
    }
    const grid = this.DOMParentComponent;
    const type = grid
      ? grid.isGridCollapsed
        ? GridPreferenceType.REDUCED
        : GridPreferenceType.EXPANDED
      : GridPreferenceType.EXPANDED;
    if (type === GridPreferenceType.EXPANDED) {
      this.expandedColumnState = this.agGrid.columnApi.getColumnState();
    } else {
      this.reducedColumnState = this.agGrid.columnApi.getColumnState();
    }
  }

  private updateSecondaryColumns(
    request: CompiereDataGridRequestJSON,
    response: any
  ) {
    const valueCols = request.valueCols;
    if (request.pivotMode && request.pivotCols.length > 0) {
      const secondaryColDefs = this.createSecondaryColumns(
        response.secondaryColumnFields,
        valueCols
      );
      // apply sort state
      let secondaryColumnSortStates = [];
      if (this.agGrid.columnApi.getSecondaryColumns()) {
        secondaryColumnSortStates = this.agGrid.columnApi
          .getSecondaryColumns()
          .map((col) => {
            return { colId: col.getColId(), sort: col.getSort() };
          });
      }
      this.agGrid.columnApi.setSecondaryColumns(secondaryColDefs);
      if (this.agGrid.columnApi.getSecondaryColumns()) {
        secondaryColumnSortStates.forEach((sortState) => {
          const colFound = this.agGrid.columnApi
            .getSecondaryColumns()
            .find((secCol) => secCol.getColId() === sortState.colId);
          if (colFound) {
            colFound.setSort(sortState.sort);
          }
        });
      }
    } else {
      this.agGrid.columnApi.setSecondaryColumns([]);
    }
  }

  private createSecondaryColumns(
    secondaryColumnFields: any[],
    valueCols: any[]
  ) {
    const secondaryCols = [];

    function addColumnDefinition(colId: string, parts: string[], res: any[]) {
      if (parts.length === 0) {
        return [];
      }
      const first = parts.shift();
      const existing = res.find((r) => r.groupId === first);
      if (existing) {
        existing['children'] = addColumnDefinition(
          colId,
          parts,
          existing.children
        );
      } else {
        const colDef = {};
        const isGroup = parts.length > 0;
        if (isGroup) {
          colDef['groupId'] = first;
          colDef['headerName'] = first;
        } else {
          const valueCol = valueCols.find((r) => r.field === first);
          colDef['colId'] = colId;
          colDef['headerName'] = valueCol.displayName;
          colDef['field'] = colId;
        }
        const children = addColumnDefinition(colId, parts, []);
        if (children.length > 0) {
          colDef['children'] = children;
        }
        res.push(colDef);
      }
      return res;
    }

    secondaryColumnFields.sort();
    secondaryColumnFields.forEach((field) => {
      let fieldToUse = '';
      valueCols.forEach((vc) => {
        fieldToUse = field.replace('_' + vc.field, '');
      });
      let fieldToSplit = fieldToUse.split('_');
      fieldToSplit = [...fieldToSplit, ...valueCols.map((vc) => vc.field)];
      return addColumnDefinition(field, fieldToSplit, secondaryCols);
    });
    return secondaryCols;
  }
  onGridReady(params: GridReadyEvent) {
    this.subscriptions.push(
      this.getInfoWindowDefaultValue()
        .pipe(
          switchMap(() => {
            return this.loadPreference(true);
          })
        )
        .subscribe()
    );
    this.setListeners(params.api);
  }
  setListeners(api: GridApi) {
    [
      'columnVisible',
      'columnMoved',
      'columnRowGroupChanged',
      'columnValueChanged',
      'columnPivotModeChanged',
      'columnPivotChanged',
      'columnGroupOpened',
      'sortChanged',
    ].forEach((event) =>
      api.addEventListener(event, () => {
        this.updateColumnState();
      })
    );
  }
  getInfoWindowDefaultValue() {
    if (
      this.windowType === IupicsMenuType.INFO_WINDOW &&
      this.data.details.entityID
    ) {
      return this.dataStore
        .getInfoWindowDefaultValues(
          this.data.details.entityID,
          this.getCurrentContext()
        )
        .pipe(
          switchMap((defaultValues) => {
            const currentFilterModel = this.filterModel ? this.filterModel : {};
            if (defaultValues && this.data.columnsDetails) {
              defaultValues.forEach((dv) => {
                const columnDetail = (
                  this.data.columnsDetails as Map<string, any>
                ).get(dv.columnname);
                if (columnDetail) {
                  const filterType = UICreatorUtils.getFilterTypeFromReference(
                    columnDetail.field.field.AD_Reference_ID
                  );
                  const operators = [OperatorFilterType.EQUALS].filter(
                    (i) => i
                  );
                  const values = [dv.defaultValue];
                  currentFilterModel[dv.columnname] = {
                    filterType,
                    operators,
                    values,
                  };
                }
              });
              this.filterModel = currentFilterModel;
              return of(true);
            } else {
              return of(false);
            }
          })
        );
    } else {
      return of(false);
    }
  }
  loadPreference(isInit = false): Observable<boolean> {
    const grid = this.DOMParentComponent;
    const type = grid
      ? grid.isGridCollapsed
        ? GridPreferenceType.REDUCED
        : GridPreferenceType.EXPANDED
      : GridPreferenceType.EXPANDED;
    const newGridPreference: GridPreference = {
      userGridPreferenceID: -1,
      tableID: this.data?.details?.entityID,
      tabID: this.data?.ADTabID,
      formDetailID: (<any>this.data)?.AD_FormDetail_ID,
      gridState: JSON.stringify(this.agGrid.columnApi.getColumnState()),
      name: '',
    };
    return this.gridPreferencesService
      .getGridPreference(
        this.gridPreference ? this.gridPreference : newGridPreference
      )
      .pipe(
        map((gridPreferenceResponse) => {
          if (gridPreferenceResponse) {
            if (!this.gridPreference) {
              isInit = true;
            }
            if (isInit) {
              if (<GridViewUiComponent>this.DOMParentComponent) {
                this.DOMParentComponent.gridPrefLoaded.emit();
              } else {
                this.gridPrefLoaded.emit();
              }
            }
            this.gridPreference = gridPreferenceResponse;
          } else {
            this.gridPreference = newGridPreference;
          }
          return this.loadGridPreferenceResponse(type, isInit);
        }),
        catchError(() => of(false))
      );
  }
  getColumnApi() {
    if (this.agGrid) {
      return this.agGrid.columnApi;
    } else {
      return null;
    }
  }

  loadGridPreferenceResponse(
    type: GridPreferenceType,
    isInit = false,
    isFromUF = false
  ) {
    let loaded = false;
    let columnState: ColumnState[];
    let columnStateReduced: ColumnState[];
    if (
      isInit &&
      this.gridPreference &&
      this.gridPreference.userGridPreferenceID > 0
    ) {
      columnState = JSON.parse(this.gridPreference.gridState);
      columnStateReduced = JSON.parse(this.gridPreference.gridStateReduced);
      if (isInit && !isNil(this.initRequest) && !this.isInitRequestEmpty()) {
        const request: CompiereDataGridRequestJSON = JSON.parse(
          this.gridPreference.gridRequest
        );
        Object.assign(request, this.initRequest);
        request.pivotMode = request.pivotCols.length > 0;
        this.gridPreference.gridRequest = JSON.stringify(request);
      }
      loaded = true;
    }
    this.preventGetRows = isFromUF;
    if (loaded) {
      this.expandedColumnState = columnState;
      this.reducedColumnState = columnStateReduced;
      this.applyColumnState(
        type === GridPreferenceType.EXPANDED ? columnState : columnStateReduced,
        type
      );
    } else {
      this.applyColumnState(
        type === GridPreferenceType.EXPANDED
          ? this.expandedColumnState
          : this.reducedColumnState,
        type
      );
      loaded = true;
    }

    if (isInit && !this.dataSourceCreated) {
      this.createServerDataSource();
    }
    return loaded;
  }

  private isInitRequestEmpty(): boolean {
    return (
      !this.initRequest ||
      ((isNil(this.initRequest.filterModel) ||
        Object.keys(this.initRequest.filterModel).length === 0) &&
        (isNil(this.initRequest.sortModel) ||
          this.initRequest.sortModel.length === 0) &&
        (isNil(this.initRequest.rowGroupCols) ||
          this.initRequest.rowGroupCols.length === 0))
    );
  }

  applyColumnState(
    columnStates: ColumnState[],
    type: GridPreferenceType = null
  ): void {
    this.fromUFData = {
      forceSort: true,
      forceFilter: true,
      forceGroup: true,
      forceValueCols: true,
      forcePivotCols: true,
      forcePivotMode: true,
    };
    if (columnStates) {
      this.agGrid.columnApi.applyColumnState({
        state: columnStates,
        applyOrder: true,
      });
    } else {
      if (type === GridPreferenceType.EXPANDED) {
        this.agGrid.columnApi.resetColumnState();
      } else {
        this.onlyOneColumn(false);
      }
    }
  }

  onPageChange() {
    this.currentSelectPageIndex = this.agGrid.api.paginationGetCurrentPage();
  }

  private getConstant(prop: string) {
    const maxNbLines =
      this.data && this.data['maxNbLines']
        ? this.data['maxNbLines']
        : GridTabInfinityScrollConstant.DEFAULT_MAXIMUM_NUMBER_LINES;
    const extraHeight =
      this.pinnedColKeys && this.pinnedColKeys.length > 0 ? 127 : 100;
    switch (prop) {
      case 'DEFAULT_MAX_TABLE_HEIGHT':
        return (
          maxNbLines * GridTabInfinityScrollConstant.ROW_PAGINATOR_HEIGHT +
          extraHeight +
          'px'
        );
      case 'DEFAULT_CALCULE_TABLE_HEIGHT':
        return (
          maxNbLines * GridTabInfinityScrollConstant.ROW_PAGINATOR_HEIGHT +
          extraHeight
        );
      case 'DEFAULT_MAXIMUM_NUMBER_LINES':
        return maxNbLines;

      default:
        break;
    }
  }

  private columnFormatter(params: ValueFormatterParams) {
    const value =
      params.value != null && params.value != undefined
        ? params.value
        : params?.data[params.colDef.field];
    const fieldInfo = this.columnDatas.find(
      (cd) => cd.data.ColumnName === params.colDef.field
    );
    const componentName = fieldInfo
      ? UICreatorUtils.getComponentNameFromReference(
          fieldInfo.data.AD_Reference_ID
        )
      : null;
    const displayValueFound = this.getDisplayValue(params.colDef.field, value);
    if (displayValueFound) {
      return displayValueFound;
    }
    if (params.colDef.field === 'Data_UUID') {
      return '';
    } else if (
      value &&
      value.displayValue !== undefined &&
      value.displayValue !== null
    ) {
      return value.displayValue;
    }
    if (value && value.displayValue === null) {
      return '';
    } else if (value !== null) {
      if (
        componentName === 'CalendarUiComponent' &&
        (isNil(params.colDef.cellRenderer) ||
          params.colDef.cellRenderer !== 'calendarRenderer')
      ) {
        /**
         * @start_custo_code
         */
        if (
          /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[T][0]{2}[:][0]{2}[:][0]{2}/.test(
            value
          )
        ) {
          // Test si date ==> parse pour la locale
          return moment(value).format(Global.display_dateFormat);
        } else if (/^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[T]/.test(value)) {
          return moment(value).format(`${Global.display_dateFormat} LTS`);
        }
        /**
         * @end_custo_code
         */

        // Test si date ==> parse pour la locale
        if (value) {
          if (fieldInfo.data.AD_Reference_ID === 15 /*Date*/) {
            return moment(value).format(Global.display_dateFormat);
          } else {
            return moment(value).format(Global.display_dateTimeFormat);
          }
        } else {
          return '';
        }
        // } else if (componentName === 'InputNumberUiComponent') {
        //   return this.formatNumberPrecision(value, fieldInfo, params.data);
      } else {
        return value;
      }
    } else {
      return '';
    }
  }

  // private fillPrecisionCache(rowData: any[]): Observable<any> {
  //   const obsArr = [];
  //   rowData.forEach((row) => {
  //     let cacheName = null;
  //     let id = null;
  //     if (row['C_UOM_ID']) {
  //       cacheName = CacheName.UOM;
  //       id = row['C_UOM_ID'];
  //     } else if (row['C_AcctSchema_ID']) {
  //       cacheName = CacheName.ACCT_SCHEMA;
  //       id = row['C_AcctSchema_ID'];
  //     } else if (row['M_PriceList_ID']) {
  //       cacheName = CacheName.PRICE_LIST;
  //       id = row['M_PriceList_ID'];
  //     } else if (row['C_Currency_ID']) {
  //       cacheName = CacheName.CURRENCY;
  //       id = row['C_Currency_ID'];
  //     }
  //     if (id && id.id !== undefined) {
  //       id = id.id;
  //     }
  //     id = parseInt(id, 10);
  //     if (id && !isNaN(id)) {
  //       obsArr.push(this.cacheService.getPrecision(cacheName, id));
  //     }
  //   });
  //   if (obsArr.length > 0) {
  //     return zip(...obsArr);
  //   } else {
  //     return of(null);
  //   }
  // }
  // private getPrecision(colInfo: any, currentCtx: any) {
  //   let precisionColumnName = 'StdPrecision';
  //   let cacheName = null;
  //   let id = null;
  //   let precision = 0;
  //   const componentName = colInfo ? UICreatorUtils.getComponentNameFromReference(colInfo?.data.AD_Reference_ID) : null;
  //   const numberType = colInfo?.data
  //     ? colInfo.data.AD_Reference_ID === 12 || colInfo.data.AD_Reference_ID === 37
  //       ? NumberType.AMOUNT
  //       : colInfo.data.AD_Reference_ID === 11
  //       ? NumberType.INTEGER
  //       : colInfo.data.AD_Reference_ID === 22
  //       ? NumberType.FLOAT
  //       : colInfo.data.AD_Reference_ID === 29
  //       ? NumberType.QUANTITY
  //       : null
  //     : null;
  //   if (numberType === NumberType.QUANTITY && currentCtx['C_UOM_ID']) {
  //     cacheName = CacheName.UOM;
  //     id = this.extractId(currentCtx['C_UOM_ID'], colInfo, componentName);
  //   } else if (numberType === NumberType.AMOUNT) {
  //     if (currentCtx['M_PriceList_ID']) {
  //       precisionColumnName = 'PricePrecision';
  //       cacheName = CacheName.PRICE_LIST;
  //       id = this.extractId(currentCtx['M_PriceList_ID'], colInfo, componentName);
  //     } else if (currentCtx['C_Currency_ID']) {
  //       cacheName = CacheName.CURRENCY;
  //       id = this.extractId(currentCtx['C_Currency_ID'], colInfo, componentName);
  //     } else if (currentCtx['C_AcctSchema_ID']) {
  //       cacheName = CacheName.ACCT_SCHEMA;
  //       id = this.extractId(currentCtx['C_AcctSchema_ID'], colInfo, componentName);
  //     } else if (currentCtx['#StdPrecision']) {
  //       precision = currentCtx['#StdPrecision'];
  //     }
  //   }
  //   if (cacheName !== null && id > 0) {
  //     const cacheValue = this.cacheService.getCacheValueById(cacheName, id);
  //     return cacheValue ? cacheValue[precisionColumnName] : currentCtx['#StdPrecision'];
  //   } else {
  //     return precision;
  //   }
  // }
  // private extractId(valueToExtract: any, colInfo: any, componentName: string): any {
  //   let value = cloneDeep(valueToExtract);
  //   if (value && value.id !== undefined) {
  //     value = value.id;
  //   }
  //   let id = value;
  //   if (componentName === 'AutocompleteUiComponent' && (!colInfo.details || colInfo.details.tableName !== 'AD_Ref_List')) {
  //     if (value !== undefined && value !== null && /^[-+]?(\d+|Infinity)$/.test(value)) {
  //       id = parseInt(value, 10);
  //     }
  //   }
  //   return id;
  // }
  // private formatNumberPrecision(value: number, fieldInfo: any, parentData: IupicsData) {
  //   const ctx = EditViewUtils.mergeCurrentDataDeepCopy(this.getCurrentContext(), parentData, true, true);
  //   const precision = this.getPrecision(fieldInfo, ctx);
  //   const numberFormat = precision ? `1.${precision}-${precision}` : '';
  //   return value != null || value != undefined
  //     ? formatNumber(value, this.connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-'), numberFormat)
  //     : value;
  // }
}
